import * as React from 'react'
import {Password, Email} from '/components/Form'
import {Form, Icon, Input, Button, Checkbox} from 'antd'
import './LoginForm.less'

export const LoginForm = props => {
  const isLoading = props.status === 'loading'
  return (
    <Form
      onSubmit={props.handleSubmit}
      className='login-form'
      style={{textAlign: 'center'}}
    >
      <img
        src='https://gcmgames-static.s3.amazonaws.com/img/logo.png'
        style={{height: '50px', marginBottom: '20px'}}
      />
      <Form.Item>
        <Email id='login-email' />
      </Form.Item>
      <Form.Item>
        <Password id='login-password' />
      </Form.Item>
      {props.status === 'error' && <p>{props.errorMsg}</p>}
      {props.status === 'success' && <p>Login efetuado com sucesso!</p>}
      <Form.Item>
        <Button
          loading={isLoading}
          type='primary'
          htmlType='submit'
          className='login-form-button'
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  )
}
