import * as React from 'react'
import { Form, Input, Select, Icon, Descriptions, Spin, Typography } from 'antd'
const { TextArea } = Input
const { Item } = Form
const { Option } = Select
const { Text } = Typography
import { orderService } from '/services'

import { FormattedDate } from '/components/Misc'

export class StoreOrderDetails extends React.Component {
  state = {
    loading: true,
    userInfo: {},
    customerNotes: '',
    log: { timestamp: null, userEmail: null },
  }

  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    const { formikProps } = this.props
    const { values } = formikProps

    const customerNotes = await orderService.loadCustomerNotes(
      values.buyer.document,
    )

    values.customerNotes = customerNotes

    this.setState({ loading: false })
  }

  render() {
    const { formikProps } = this.props
    const { values, handleBlur, handleChange, handleSubmit } = formikProps

    const formLayout = {
      labelAlign: 'left',
      labelCol: {
        md: { span: 5 },
      },
      wrapperCol: {
        md: { span: 19 },
      },
    }

    return (
      <Spin spinning={this.state.loading}>
        <Form {...formLayout} onSubmit={handleSubmit}>
          <Item label='ID do pedido'>
            <Input
              disabled
              name='id'
              prefix={
                <Icon type='idcard' style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id}
            />
          </Item>
          <Item label='Pedido'>
            <Select
              disabled
              defaultValue='confirmed'
              prefix={
                <Icon type='dollar' style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              value={values.status}
              onBlur={handleBlur}
            >
              <Option value='created'>Criado</Option>
              <Option value='approved'>Aprovado</Option>
              <Option value='cancelled'>Cancelado</Option>
            </Select>
          </Item>
          <Item label='Título'>
            <Input
              name='title'
              placeholder='Titulo'
              prefix={
                <Icon type='profile' style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              disabled
            />
          </Item>
          <Item label='Dados do cliente'>
            {this.state.userInfo && (
              <Descriptions title='' layout='vertical' bordered>
                <Descriptions.Item label='Nome'>
                  {values.buyer.name}
                </Descriptions.Item>
                <Descriptions.Item label='Celular'>
                  {values.buyer.phone}
                </Descriptions.Item>
                <Descriptions.Item label='Endereço' span={2}>
                  {values.address &&
                    values.address.addressLine +
                      ', ' +
                      (values.address.extraData || '') +
                      ', ' +
                      (values.address.zipCode || '')}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Item>

          <Item label='Pagamento'>
            <Select
              disabled
              defaultValue='approved'
              prefix={
                <Icon type='dollar' style={{ color: 'rgba(0,0,0,.25)' }} />
              }
              value={values.paymentStatus}
              onBlur={handleBlur}
            >
              <Option value='pending'>Pendente</Option>
              <Option value='analysis'>Análise</Option>
              <Option value='approved'>Aprovado</Option>
              <Option value='validated_tel'>Validado_TEL</Option>
              <Option value='validated_doc'>Validado_DOC</Option>
              <Option value='cancelled'>Cancelado</Option>
            </Select>
          </Item>
          <Item label='Envio'>
            <Select
              disabled
              defaultValue='pending'
              value={values.deliveryStatus}
              onBlur={handleBlur}
            >
              <Option value='pending'>Pendente</Option>
              <Option value='sent'>Enviado</Option>
              <Option value='delivered'>Entregue</Option>
              <Option value='aborted'>Abortado</Option>
              <Option value='in_queue'>Na fila</Option>
            </Select>
          </Item>
          <Item label='Data de criação'>
            <FormattedDate date={values.createdAt} />
          </Item>
          <Item label='Observações sobre o cliente'>
            <TextArea
              rows={6}
              value={values.customerNotes.notes}
              onBlur={handleBlur}
              onChange={handleChange}
              name={'customerNotes.notes'}
            />
            <Text type='secondary'>
              {values.customerNotes.timestamp
                ? `Editado por último em: ${values.customerNotes.timestamp} por ${values.customerNotes.user}`
                : ''}
            </Text>
          </Item>
        </Form>
      </Spin>
    )
  }
}
