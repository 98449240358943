import * as React from 'react'
import {LogList} from './LogList'
import {logService} from '/services'

export class LogListContainer extends React.Component {
  loadLogs = async ({page = 1, pageSize = 20, query} = {}) => {
    const {user} = this.props

    // If handling an empty query, list all logs
    if (!query) {
      const {logs, total} = await logService.listByPage(page, pageSize)

      return {page, pageSize, data: logs, totalCount: total}
    }

    const {logs, total} = await logService.search(query, {page, pageSize})

    return {page, pageSize, data: logs, totalCount: total}
  }

  render() {
    return <LogList
    loadLogs={this.loadLogs}
    />
  }
}
