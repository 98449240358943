import * as React from 'react'
import {
  message,
  Card,
  Badge,
  Button,
  Row,
  Col,
  Typography,
  DatePicker,
} from 'antd'
import { Link } from 'react-router-dom'
import { DataTable } from '/components/DataTable'
import { ProductForm } from '/components/ProductForm'
import { sleep } from '/utils/promises'
import { productService } from '/services'
const { Title, Text } = Typography

function makeInitialValues(product) {
  let initialValues = {
    id: '',
    ezId: '',
    mlId: '',
    name: '',
    description: '',
    value: '',
    type: 'product',
    categoryId: null,
    autoDelivery: true,
  }
  if (product) {
    initialValues = { ...product, categoryId: null }
  }
  return initialValues
}

export function ProductList({
  match,
  loadProducts,
  onEdit,
  onDelete,
  onCreate,
  schema,
  searchCategories,
  searchHypeProducts,
  searchProducts,
  onChangeDate,
  onCreateBlackhawkReport,
  onCreateIncommReport,
  onCreateSalesReport,
}) {
  return (
    <Card title='Produtos' bordered={false}>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          margin: '5px 0 20px 0',
        }}
      >
        <Col>
          <Text style={{ fontWeight: 500 }}>Gerar Relatório</Text>

          <Row style={{ marginTop: '7px' }}>
            <DatePicker
              style={{ width: 150, marginRight: '20px' }}
              placeholder='Data Inicial'
              format='DD/MM/YYYY'
              onChange={value => onChangeDate('start', value)}
            />

            <DatePicker
              style={{ width: 150, marginRight: '20px' }}
              placeholder='Data Final'
              format='DD/MM/YYYY'
              onChange={value => onChangeDate('end', value)}
            />
          </Row>
        </Col>

        <Button.Group style={{ marginTop: '28px' }}>
          <Button onClick={onCreateBlackhawkReport}>Blackhawk</Button>
          <Button onClick={onCreateIncommReport}>Incomm</Button>
          <Button onClick={onCreateSalesReport}>Vendas</Button>
        </Button.Group>
      </Row>

      <Row style={{ marginTop: '20px', textAlign: 'right' }}>
        <Col>
          <Link to={`${match.path}/rixty`}>
            <Button type='dashed'>Comprar produtos - Rixty</Button>
          </Link>
        </Col>
      </Row>
      <DataTable
        searchText={'Busque pelo nome ou ID do produto'}
        dataLoader={loadProducts}
        header={header}
        delete={{
          text: 'Tem certeza que deseja deletar',
          onDelete: product => onDelete(product.id),
        }}
        edit={{
          type: 'modal',
          text: 'Editar produto',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: onEdit,
            schema,
          },
          render: (formikProps, product) => {
            return (
              <ProductForm
                formikProps={formikProps}
                formType='edit'
                searchCategories={searchCategories}
                searchHypeProducts={searchHypeProducts}
                searchProducts={searchProducts}
                product={product}
              />
            )
          },
        }}
        create={{
          type: 'modal',
          text: 'Criar produto',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: onCreate,
            schema,
          },
          render: formikProps => {
            return (
              <ProductForm
                formikProps={formikProps}
                formType='create'
                searchCategories={searchCategories}
                searchProducts={searchProducts}
                searchHypeProducts={searchHypeProducts}
              />
            )
          },
        }}
      />
    </Card>
  )
}

const header = {
  id: { title: 'ID' },
  ezId: { title: 'ID EZ Commerce' },
  mlId: { title: 'ID ML' },
  name: {
    title: 'Nome',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  description: {
    title: 'Descrição',
    render: description => {
      const newText =
        description.length >= 100
          ? description.slice(0, 100) + '...'
          : description
      return <Text>{newText}</Text>
    },
  },
  value: {
    title: 'Valor',
    render: value => <Text>{`R$ ${value}`}</Text>,
    sorter: (a, b) => a.value - b.value,
  },
  stockAlert: {
    title: 'Estoque mínimo',
  },
  type: {
    title: 'Tipo',
    render: type => {
      let text = ''

      if (type == 'product') text = 'Produto'
      if (type == 'pack') text = 'Pacote'
      if (type == 'hype') text = 'Hype'
      if (type == 'blackhawk') text = 'Blackhawk'
      if (type == 'incomm') text = 'Incomm'

      return <Text>{text}</Text>
    },
  },
  categoryId: { title: 'ID Categoria' },
  deliverableCount: {
    title: 'Estoque',
    render: count => {
      return (
        <Badge
          showZero={true}
          overflowCount={Infinity}
          count={count}
          style={{
            backgroundColor: count >= 10 ? '#52c41a' : 'red',
            color: '#fff',
          }}
        />
      )
    },
  },
}
