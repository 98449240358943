import React, { useState } from 'react'
import { Button, Input, Form, Card, DatePicker } from 'antd'
import { RemoteSelect } from '/components/Form'
import { productService, hypeService } from '../../services'

const { Item } = Form
export function HypeTransactionsList() {
  const [productId, setProductId] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [name, setName] = useState(null)
  const [cpf, setCpf] = useState(null)
  const [birthDate, setBirthDate] = useState(null)
  const [amount, setAmount] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const generateFileForDownload = redirectLinks => {
    if (!redirectLinks) {
      return
    }

    const now = new Date()
    const file = new Blob([redirectLinks.join('\n')], { type: 'text/plain' })
    const fileName = [
      now.getDate(),
      now.getMonth() + 1,
      now.getFullYear(),
      now.getHours(),
      now.getMinutes(),
    ].join('-')

    if (window.navigator.msSaveOrOpenBlob) {
      // Suppor for IE10+
      window.navigator.msSaveOrOpenBlob(file, new Date())
    } else {
      // Other browsers
      const a = document.createElement('a')
      const url = URL.createObjectURL(file)
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      // Fake a click to force the download to start
      a.click()
      setTimeout(function() {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    }
  }
  const submit = async () => {
    setSubmitting(true)
    const { data: redirectLinks } = await hypeService.startBulkRedeem({
      productId,
      orderId,
      amount,
      customer: { name, birthDate, document: cpf },
    })
    generateFileForDownload(redirectLinks)
    setSubmitting(false)
  }

  const searchHypeProducts = async query => {
    const result = await productService.listHypeCatalog()

    return result
      .filter(p => p.name.match(new RegExp(`${query}`, 'i')) || p.id == query)
      .map(p => ({
        text: p.name,
        value: p.id,
      }))
  }
  return (
    <Card title='Transações Hype' bordered={false}>
      <Form>
        <Item label='Produto' name='productId'>
          <RemoteSelect
            placeholder='Busque pelo produto hype'
            dataSearcher={searchHypeProducts}
            onChange={value => setProductId(value)}
          />
        </Item>
        <Item label='Quantidade' name='amount'>
          <Input onChange={e => setAmount(parseInt(e.target.value))} />
        </Item>
        <Item label='ID Pedido' name='orderId'>
          <Input onChange={e => setOrderId(e.target.value)} />
        </Item>
        <Item label='Nome' name='name'>
          <Input onChange={e => setName(e.target.value)} />
        </Item>
        <Item label='CPF' name='cpf'>
          <Input onChange={e => setCpf(e.target.value)} />
        </Item>
        <Item label='Data de nascimento' name='birthDate'>
          <DatePicker onChange={value => setBirthDate(value)} />
        </Item>
        <Button loading={submitting} onClick={submit} type='primary'>
          Iniciar compra
        </Button>
      </Form>
    </Card>
  )
}
