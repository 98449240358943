import * as React from 'react'
import { Route } from 'react-router-dom'
import { DeliverableListContainer } from '/components/DeliverableList'
import { DeliverableCreateContainer } from '/components/DeliverableCreate'
import { BlackListContainer } from '/components/BlackList'

export class DeliverablesPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { match } = this.props
    return (
      <div>
        <Route
          exact
          path={`${match.path}`}
          render={props => <DeliverableListContainer {...this.props} />}
        />
        <Route
          path={`${match.path}/create`}
          render={props => <DeliverableCreateContainer {...this.props} />}
        />
        <Route
          exact
          path={`${match.path}/blacklist`}
          render={props => <BlackListContainer {...this.props} />}
        />
      </div>
    )
  }
}
