import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'
import qs from 'query-string'

export const deliverableService = {
  async listByPage(page, pageSize, filter, productId) {
    const queryParams = qs.stringify({ page, pageSize, filter, productId })
    const { data } = await client.get(`/deliverables?${queryParams}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return {
      total: data.total,
      deliverables: data.deliverables,
    }
  },

  async getAll() {
    const { data } = await client.get('/deliverables', {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data.deliverables
  },

  async create(deliverables) {
    const { status, data } = await client.post('/deliverables', deliverables, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return { status, data }
  },

  async delete(deliverable) {
    const { status } = await client.delete(`/deliverables/${deliverable.id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return status
  },

  async editById(id, { productId, delivered, autoDelivery }) {
    const { status } = await client.patch(
      `/deliverables/${id}`,
      { productId, delivered, autoDelivery },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return status
  },

  async search(query, { page, pageSize, filter, productId } = {}) {
    const params = qs.stringify({
      query,
      page,
      pageSize,
      filter,
      productId,
    })

    const { data } = await client.get(`/deliverables/search?${params}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async getAccesses(link) {
    const { data } = await client.get(
      `/deliverables/${encodeURIComponent(link)}/accesses`,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return data
  },

  async listBlacklist({ query, page = 1, pageSize = 20 }) {
    let searchQuery = query ? query.trim() : null

    const requestQuery =
      searchQuery && searchQuery.length >= 1
        ? `/deliverables/blacklist/search?q=${query}`
        : `/deliverables/blacklist?page=${page}&pageSize=${pageSize}`

    const { data } = await client.get(requestQuery, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async createBlacklistEntry(entry) {
    const { data, status } = await client.post(
      `/deliverables/blacklist`,
      entry,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )
    return data
  },

  async deleteBlacklistEntry(id) {
    const { status } = await client.delete(`/deliverables/blacklist/${id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return status
  },
}
