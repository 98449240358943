import * as React from 'react'
import * as Yup from 'yup'
import { Card, Button, Modal, Typography } from 'antd'
import { DeliveryBundleForm } from '/components/DeliveryBundleForm'
import { FormattedDate } from '/components/Misc'
import { DataTable } from '/components/DataTable'
import './DeliveriesList.less'
import { pick } from 'ramda'

const { Text, Paragraph } = Typography

const EditDeliveryBundleSchema = Yup.object().shape({
  smsAuthEnabled: Yup.boolean(),
  isBlocked: Yup.boolean(),
  isGift: Yup.boolean(),
})

export function DeliveriesList({
  loadDeliveries,
  loadDeliveryItems,
  editDeliveryBundle,
  match,
  user,
}) {
  const header = {
    id: { title: 'ID' },
    senderName: {
      title: 'Enviado por',
    },
    timestamp: {
      title: 'Data',
      render: date => {
        return <FormattedDate date={date} />
      },
    },
    recipients: {
      title: 'Itens enviados',
      render: (_, record) => (
        <Button
          onClick={async () => {
            const items = await loadDeliveryItems(record)
            showItems(items)
          }}
        >
          Ver itens
        </Button>
      ),
    },
    pin: {
      title: 'PIN',
      render: (_, record) => (
        <a
          href={`${process.env.PROXY_PREFIX}/${record.deliveryBundle.pin}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{ textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.65)' }}
        >
          {record.deliveryBundle.pin}
        </a>
      ),
    },
  }

  return (
    <Card title='Entregas' bordered={false}>
      <DataTable
        searchText={'Busque pelo e-mail do cliente, link ou id da entrega'}
        dataLoader={loadDeliveries}
        header={header}
        create={
          user.role !== 'monitor'
            ? {
                type: 'link',
                text: 'Realizar entrega',
                link: `${match.path}/create`,
              }
            : null
        }
        edit={{
          type: 'modal',
          text: 'Editar entrega',
          formik: {
            initialValues: delivery => delivery,
            onSubmit: editDeliveryBundle,
            schema: EditDeliveryBundleSchema,
          },
          render: formikProps => {
            return <DeliveryBundleForm formikProps={formikProps} />
          },
        }}
      />
    </Card>
  )
}

function showItems(recipients) {
  Modal.info({
    style: { minWidth: '700px' },
    title: 'Itens enviados',
    content: (
      <div>
        {recipients.map(recipient => {
          return (
            <div className='product-entry'>
              <Paragraph strong style={{ fontSize: '16px' }}>
                {recipient.email}
              </Paragraph>
              {recipient.products.map(product => {
                return (
                  <div>
                    <Text strong>{product.name}</Text>
                    <ol>
                      {product.links.map(link => (
                        <li key={link}>
                          <Text copyable>{link}</Text>
                        </li>
                      ))}
                    </ol>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    ),
    onOk() {},
  })
}
