import * as React from 'react'
import {Menu, Dropdown, Avatar, Icon} from 'antd'
import './UserAvatar.less'

const {Item} = Menu

export class UserAvatar extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Dropdown overlay={() =>
        <Menu>
          <Item onClick={this.props.logout}>
            <a rel="noopener noreferrer" href='#'>
              <Icon type='logout' style={{marginRight: '10px'}}/>
              Sair
            </a>
          </Item>
        </Menu>
      }>
        <a style={{color: 'rgba(255, 255, 255, 0.65)'}} className="ant-dropdown-link" href='#'>
          <Avatar style={{color: 'rgba(255, 255, 255, 0.65)'}} className='user-avatar' icon='user' />
          <span>{this.props.user.name || this.props.user.email}</span>
        </a>
      </Dropdown>
    )
  }
}
