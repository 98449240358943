import * as React from 'react'
import {Card, Alert, Row, Col, Button, Icon, Input, InputNumber} from 'antd'
import {Link} from 'react-router-dom'
export class Rixty extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    return (
      <Card title='Comprar produtos - Rixty'>
        <Row gutter='12'>
          <Col span={6}>
            <Input
              value={this.props.sku}
              onChange={this.props.onSKUChange}
              placeholder='Digite o SKU do produto'
            />
          </Col>
          <Col span={1}>
            <InputNumber
              value={this.props.amount}
              min={this.props.minValue}
              max={this.props.maxValue}
              onChange={this.props.onAmountChange}
              placeholder='Qtd.'
              style={{width: '100%'}}
            />
          </Col>
        </Row>
        {this.props.feedbackMessage && this.props.feedbackType && (
          <Row gutter={12} style={{margin: '20px 0'}}>
            <Col span={7}>
              <Alert
                description={this.props.feedbackMessage}
                type={this.props.feedbackType}
                showIcon
              />
            </Col>
          </Row>
        )}
        <Row gutter={12} style={{margin: '20px 0'}}>
          <Col span={7}>
            <Button
              type='primary'
              onClick={this.props.onSubmit}
              loading={this.props.loading ? true : undefined}
              style={{width: '100%'}}
            >
              Executar compra
            </Button>
          </Col>
        </Row>
        <Row gutter={12} style={{'margin-top': '80px'}}>
          <Col span={7}>
            <Link to='/products'>
              <Button style={{width: '100%'}}>
                <Icon type='left' />
                Voltar
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    )
  }
}
