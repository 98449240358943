import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'
import qs from 'query-string'

export const categoryService = {
  async listByPage(page, pageSize) {
    const { data } = await client.get(
      `/categories?page=${page}&pageSize=${pageSize}`,
    )

    return {
      total: data.total,
      categories: data.categories,
    }
  },

  async search(idOrName, { page, pageSize } = {}) {
    const params = qs.stringify({
      query: idOrName,
      page,
      pageSize,
    })

    const { data } = await client.get(`/categories/search?${params}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async createCategory(category) {
    const { data } = await client.post('/categories', category, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data.categoryId
  },

  async editCategory({
    id,
    name,
    tutorialUrl,
    tutorialDescription,
    automaticRedeemUrl,
  }) {
    const { status } = await client.patch(
      `/categories/${id}`,
      { name, tutorialUrl, tutorialDescription, automaticRedeemUrl },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return status == 200
  },

  async deleteCategory(category) {
    const { status } = await client.delete(`/categories/${category.id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return status
  },
}
