import React from 'react'
import { Select, Card, Input, Button, Row, Col } from 'antd'
import { RemoteSelect } from '/components/Form'

export function DeliveriesCreate(props) {
  const {
    email,
    orderId,
    amount,
    onAmountChange,
    onEmailChange,
    onOrderChange,
    onProductChange,
    productSearch,
    onSubmit,
    submitting,
    templates,
    chosenTemplateId,
    onTemplateChange,
  } = props

  const templateList = templates.map(t => (
    <Select.Option value={t.id} key={t.id}>
      {t.name}
    </Select.Option>
  ))

  return (
    <Card title='Entregas' bordered={false}>
      <Row style={{ marginBottom: 15 }}>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder='Escolha um template'
            value={chosenTemplateId}
            onChange={onTemplateChange}
          >
            {templateList}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={8}>
          <RemoteSelect
            placeholder='Busque pelo ID ou nome do produto'
            dataSearcher={productSearch}
            onChange={onProductChange}
          />
        </Col>
        <Col span={2} style={{ marginLeft: 5 }}>
          <Input
            type='number'
            name='amount'
            value={amount}
            onChange={onAmountChange}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={8}>
          <Input
            value={orderId}
            onChange={onOrderChange}
            placeholder='Digite o número do pedido'
          />
        </Col>
      </Row>
      <Row>
        <Row>
          <Col span={8}>
            <Input
              value={email}
              onChange={onEmailChange}
              placeholder='Digite o e-mail do cliente'
            />
          </Col>
        </Row>
      </Row>
      <Button
        style={{ marginTop: 10 }}
        type='primary'
        onClick={onSubmit}
        loading={submitting}
      >
        Enviar
      </Button>
    </Card>
  )
}
