import * as React from 'react'
import { Form, Checkbox, Input, Icon } from 'antd'

const { Item } = Form

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

export function DeliveryBundleForm({ formikProps }) {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setValues,
  } = formikProps

  const formLayout = {
    labelAlign: 'left',
    labelCol: {
      md: { span: 8 },
    },
    wrapperCol: {
      md: { span: 10 },
    },
  }

  return (
    <Form {...formLayout} onSubmit={handleSubmit}>
      <Item label='PIN'>
        <Input
          disabled
          name='id'
          prefix={<Icon type='idcard' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          value={values.deliveryBundle.pin}
        />
      </Item>
      <Item
        label='Validação via SMS'
        {...fromFormikErrors('smsAuthEnabled', errors, touched)}
      >
        <Checkbox
          name='smsAuthEnabled'
          onBlur={handleBlur}
          onClick={event => {
            const newValues = { ...values }
            newValues.deliveryBundle.smsAuthEnabled = event.target.checked
            setValues(newValues)
          }}
          checked={values.deliveryBundle.smsAuthEnabled}
        />
      </Item>
      <Item
        label='Entrega bloqueada'
        {...fromFormikErrors('isBlocked', errors, touched)}
      >
        <Checkbox
          name='isBlocked'
          onBlur={handleBlur}
          onClick={event => {
            const newValues = { ...values }
            newValues.deliveryBundle.isBlocked = event.target.checked
            setValues(newValues)
          }}
          checked={values.deliveryBundle.isBlocked}
        />
      </Item>
      <Item
        label='Caixa de Presente'
        {...fromFormikErrors('isGift', errors, touched)}
      >
        <Checkbox
          name='isGift'
          onBlur={handleBlur}
          onClick={event => {
            const newValues = { ...values }
            newValues.deliveryBundle.isGift = event.target.checked
            setValues(newValues)
          }}
          checked={values.deliveryBundle.isGift}
        />
      </Item>
    </Form>
  )
}
