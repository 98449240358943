import * as React from 'react'
import { Link } from 'react-router-dom'
import { Tag, Modal, Icon, Table, Card, Button, Typography } from 'antd'
import { DataTable, FilterTypes } from '/components/DataTable'
import { UserAgent, FormattedDate, Checkmark } from '/components/Misc'
import * as Yup from 'yup'

import { EditDeliverableForm } from './EditDeliverableForm'

const { Text } = Typography

function makeInitialValues(deliverable) {
  return {
    id: deliverable.id,
    content: deliverable.content,
    link: deliverable.link,
    delivered: deliverable.delivered,
    createdAt: deliverable.createdAt,
    productId: deliverable.productId,
  }
}

const EditSchema = Yup.object().shape({
  productId: Yup.string().nullable(),
  delivered: Yup.boolean(),
})

export function DeliverableList({
  loadDeliverables,
  getDeliverableAccesses,
  searchProducts,
  onEdit,
  onDelete,
  match,
  user,
}) {
  const header = {
    id: { title: 'ID' },
    content: {
      title: 'Conteúdo',
      render: content => {
        let newText = content
        if (content.length > 100) {
          newText = content.slice(0, 100) + '...'
        }
        return <Text>{newText}</Text>
      },
    },
    link: { title: 'Link' },
    productId: { title: 'ID Produto' },
    createdAt: {
      title: 'Criado em',
      render: createdAt => {
        return <FormattedDate date={new Date(createdAt)} />
      },
    },
    delivered: {
      title: 'Entregue',
      render: delivered => <Checkmark checked={delivered} />,
    },
    accesses: {
      title: 'Accessos',
      render: (accesses, record) => (
        <Button
          onClick={async () => {
            const accesses = await getDeliverableAccesses(record)
            showAccesses(accesses, record)
          }}
        >
          <Icon type='eye' /> ({accesses})
        </Button>
      ),
    },
  }

  return (
    <Card title='Entregáveis' bordered={false}>
      <Link to={`${match.path}/blacklist`}>
        <Button>Blacklist</Button>
      </Link>
      <DataTable
        searchText={'Busque pelo link ou conteúdo do entregável'}
        dataLoader={loadDeliverables}
        size='small'
        dataLoaderFilters={{
          filter: {
            type: FilterTypes.Select,
            text: 'Selecione um filtro',
            selectOptions: [
              { text: 'Acessados', value: 'accessed' },
              { text: 'Não acessados', value: 'notAccessed' },
              { text: 'Entregues', value: 'delivered' },
              { text: 'Não entregues', value: 'notDelivered' },
              { text: 'Bloqueados', value: 'blocked' },
              { text: 'Não bloqueados', value: 'notBlocked' },
            ],
          },
          productId: {
            text: 'Digite o ID de um produto',
            type: FilterTypes.RemoteSelect,
            dataSearcher: searchProducts,
          },
        }}
        edit={{
          type: 'modal',
          text: 'Editar entregável',
          formik: {
            initialValues: deliverable => makeInitialValues(deliverable),
            onSubmit: onEdit,
            schema: EditSchema,
          },
          render: (formikProps, deliverable) => {
            return <EditDeliverableForm formikProps={formikProps} />
          },
        }}
        header={header}
        delete={{
          onDelete,
        }}
        create={
          user.role !== 'monitor'
            ? {
                type: 'link',
                text: 'Criar entregável',
                link: `${match.path}/create`,
              }
            : undefined
        }
      />
    </Card>
  )
}

function showAccesses(accesses, record) {
  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: dateString => {
        return <FormattedDate date={dateString} />
      },
    },
    { title: 'IP', dataIndex: 'ip', key: 'ip' },
    {
      title: 'Sistema',
      dataIndex: 'system',
      key: 'system',
      render: result => <UserAgent value={result} />,
    },
    { title: 'Origem', dataIndex: 'source', key: 'source' },
    {
      title: 'Celular validado',
      dataIndex: 'validatedPhone',
      key: 'validatedPhone',
    },
    {
      title: 'Acesso bloqueado',
      dataIndex: 'blocked',
      key: 'blocked',
      render: blocked => <Text>{blocked ? 'Sim' : 'Não'}</Text>,
    },
    {
      title: 'Identificador Navegador',
      dataIndex: 'identifier',
      key: 'identifier',
    },
  ]
  Modal.info({
    style: { minWidth: '1000px' },
    title: 'Acessos',
    content: (
      <React.Fragment>
        <br />
        <p>Link gerado: {record.link}</p>
        <p>Conteúdo: {record.content}</p>
        <br />
        <br />
        {accesses.length > 0 && (
          // Mark PIN as "used" only when it has at least 1 non-blocked access
          <React.Fragment>
            {accesses.filter(access => !access.blocked).length >= 1 && (
              <Tag color='green'>PIN UTILIZADO</Tag>
            )}
            <br />
            <br />
            <p>
              <b>Acessos</b>
            </p>
            <Table dataSource={accesses} columns={columns} />
          </React.Fragment>
        )}
      </React.Fragment>
    ),
    onOk() {},
  })
}
