import * as React from 'react'
import {List, Card, Icon, Popconfirm, Button, message} from 'antd'
import {Link, Route} from 'react-router-dom'
const {Item} = List

export class UsersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {deleteConfirmationId: null}
  }

  editAction = user => {
    const {match} = this.props
    return (
      <Link
        to={`${match.path}/edit/${user.id}`}
        style={{color: 'rgba(0, 0, 0, 0.45)'}}
      >
        <Icon style={{fontSize: '18px'}} type='edit' />
      </Link>
    )
  }

  deleteAction = user => {
    const {deleteConfirmationId} = this.state
    const {id} = user
    return (
      <div>
        <Icon
          style={{fontSize: '18px'}}
          type='delete'
          onClick={this.showDeleteConfirmation(id)}
        />
        <Popconfirm
          visible={deleteConfirmationId === id}
          placement='topRight'
          title='Tem certeza que deseja deletar este usuário?'
          okText='Sim, tenho certeza'
          cancelText='Cancelar'
          onConfirm={() => {
            this.hideDeleteConfirmation()
            this.props.deleteUser(id)
          }}
          onCancel={() => {
            this.hideDeleteConfirmation()
          }}
        />
      </div>
    )
  }

  render() {
    const {users} = this.props
    return (
      <Card title='Usuários' bordered={false}>
        <div style={{textAlign: 'right', margin: '20px 0'}}>
          <Link to={`${this.props.match.path}/create`}>
            <Button type='primary' icon='plus'>
              Criar usuário
            </Button>
          </Link>
        </div>
        <List
          loading={{
            spinning: this.props.loading,
            size: 'large',
            }
          }
          dataSource={users}
          renderItem={user => (
            <Item actions={[this.editAction(user), this.deleteAction(user)]}>
              <span>
                {user.name || 'Sem nome'} | {user.email}
              </span>
            </Item>
          )}
        />
      </Card>
    )
  }
  showDeleteConfirmation = userId => {
    return () => {
      this.setState({
        deleteConfirmationId: userId,
      })
    }
  }

  hideDeleteConfirmation = () => {
    this.setState({
      deleteConfirmationId: null,
    })
  }
}
