import * as React from 'react'
import {Input, Icon} from 'antd'

export const Email = props => (
  <Input
    {...props}
    prefix={<Icon type='mail' style={{color: 'rgba(0,0,0,.25)'}} />}
    placeholder='Digite seu email'
  />
)
