import * as React from 'react'
import {Card, Button} from 'antd'
import {Route, Link} from 'react-router-dom'
import {DeliverableCreateBatchContainer} from './DeliverableCreateBatchContainer'
import {DeliverableCreateSingleContainer} from './DeliverableCreateSingleContainer'
import {productService} from '/services/product'

export class DeliverableCreateContainer extends React.Component {
  state = {
    selectedProduct: null,
  }

  handleProductSelectChange = selectedProduct => {
    this.setState({
      selectedProduct,
    })
  }

  searchProducts = async query => {
    const {products} = await productService.search(query)
    return products.map(prod => ({
      text: prod.name,
      value: prod.id,
    }))
  }

  render() {
    const {match} = this.props

    return (
      <Card title='Gerar entregáveis' bordered={false}>
        <Link to='/deliverables/create/batch'>
          <Button style={{marginRight: '10px'}}>Em massa</Button>
        </Link>
        <Link to='/deliverables/create/single'>
          <Button>Individual</Button>
        </Link>
        <Route
          path={`${match.path}/create/batch`}
          render={props => (
            <DeliverableCreateBatchContainer
              {...props}
              searchProducts={this.searchProducts}
              selectedProduct={this.state.selectedProduct}
              onProductChange={this.handleProductSelectChange}
            />
          )}
        />
        <Route
          path={`${match.path}/create/single`}
          render={props => (
            <DeliverableCreateSingleContainer
              {...props}
              searchProducts={this.searchProducts}
              selectedProduct={this.state.selectedProduct}
              onProductChange={this.handleProductSelectChange}
            />
          )}
        />
      </Card>
    )
  }
}
