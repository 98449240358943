import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'
import qs from 'query-string'

export const orderService = {
  async list({ page, pageSize, query, filters }) {
    const params = qs.stringify({
      page,
      pageSize,
      query,
      ...filters,
    })
    const { data } = await client.get(`/orders?${params}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return {
      total: data.total,
      orders: data.orders,
    }
  },

  async edit(id, data) {
    const response = await client.patch(`/orders/${id}`, data, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return response
  },

  async deliveryOrder(orderId) {
    const { data } = await client.post(
      `/orders/delivery`,
      { orderId },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    const deliveryId = data ? data.deliveryId : null
    return data.deliveryId
  },

  async latestCustomerOrders(orderId) {
    const { status, data: latestOrders } = await client.get(
      `/latest-orders/${orderId}`,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return { status, latestOrders }
  },

  async requestDataAnalysis(orderId) {
    const { data } = await client.post(
      `/orders/analysis`,
      { orderId },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    const success = data ? data.success : false
    return success
  },

  async mlQualification() {
    const response = await client.post(
      `/qualify-orders`,
      {},
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return response
  },

  async batchUpdate(data) {
    const response = await client.patch(`/orders-batch-update`, data, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return response
  },

  async editCustomerNotesByOrder(id, customerNotes) {
    const response = await client.patch(
      `/logs/orders/${id}`,
      { notes: customerNotes.notes },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return response
  },

  async loadCustomerNotes(document) {
    const { data: customerNotes } = await client.get(
      `/orders/notes/customers/${document}`,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return customerNotes
  },

  async loadBlackhawkTransactions(startDate, endDate) {
    let query

    if (startDate && endDate) {
      query = `startDate=${startDate}&endDate=${endDate}`
    } else if (startDate && !endDate) {
      query = `startDate=${startDate}`
    } else {
      query = `endDate=${endDate}`
    }

    const { data } = await client.get(
      `/orders/blackhawk/transactions?${query}`,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return data
  },

  async loadIncommTransactions(startDate, endDate) {
    let query

    if (startDate && endDate) {
      query = `startDate=${startDate}&endDate=${endDate}`
    } else if (startDate && !endDate) {
      query = `startDate=${startDate}`
    } else {
      query = `endDate=${endDate}`
    }

    const { data } = await client.get(`/orders/incomm/transactions?${query}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async loadSalesReport(startDate, endDate) {
    let query = `startDate=${startDate}&endDate=${endDate}`

    const { data } = await client.post(`/orders/report?${query}`, null, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },
}
