import * as React from 'react'
import {Rixty} from './Rixty'
import {rixtyService} from '/services'

export class RixtyContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sku: '',
      amount: null,
      minValue: 1,
      maxValue: 50,
      loading: null,
      feedbackType: '',
      feedbackMessage: '',
    }
  }

  onSubmit = async () => {
    const sku = this.state.sku
    const amount = this.state.amount

    this.setState({loading: true})

    const {data, status} = await rixtyService.placeOrderBySKU(sku, amount)

    const tokens = data.successful.map(data => data['TOKEN'])
    const errors = data.errored.map(data => data['ERRORMESSAGE'])

    let feedbackType = 'success'
    let feedbackMessage = 'Compra realizada com sucesso!'

    if (!data.successful.length) {
      feedbackType = 'error'
      feedbackMessage = `Não foi possível realizar a compra. Erros: -> ${errors.join(
        ', ',
      )}`
    }

    if (data.errored.length > 0 && data.successful.length > 0) {
      feedbackType = 'warning'
      feedbackMessage = `Compra realizada parcialmente. -> Erros: ${errors.join(
        ', ',
      )}`
    }

    this.setState({
      feedbackType,
      feedbackMessage,
      loading: false,
    })

    // Generate a file with the generated tokens
    if (tokens.length) {
      const now = new Date()
      const file = new Blob([tokens.join('\n')], {type: 'text/plain'})
      const fileName = [
        now.getDate(),
        now.getMonth() + 1,
        now.getFullYear(),
        now.getHours(),
        now.getMinutes(),
      ].join('-')

      if (window.navigator.msSaveOrOpenBlob) {
        // Suppor for IE10+
        window.navigator.msSaveOrOpenBlob(file, new Date())
      } else {
        // Other browsers
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        // Fake a click to force the download to start
        a.click()
        setTimeout(function() {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }
    }
  }

  onSKUChange = e => {
    this.setState({sku: e.target.value})
  }

  onAmountChange = value => {
    this.setState({amount: value})
  }

  render() {
    return (
      <Rixty
        {...this.props}
        sku={this.state.sku}
        amount={this.state.amount}
        minValue={this.state.minValue}
        maxValue={this.state.maxValue}
        feedbackType={this.state.feedbackType}
        feedbackMessage={this.state.feedbackMessage}
        loading={this.state.loading}
        onSKUChange={this.onSKUChange}
        onAmountChange={this.onAmountChange}
        onSubmit={this.onSubmit}
      />
    )
  }
}
