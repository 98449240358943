import * as React from 'react'
import {UAParser} from 'ua-parser-js'
import {Text} from './Text'

export function UserAgent({value}) {
  const userAgent = new UAParser(value)
  const {name: osName, version: osVersion} = userAgent.getOS()
  const {major: browserVersion, name: browserName} = userAgent.getBrowser()

  const text = `${browserName || ''} ${browserVersion || ''} - ${osName ||
    ''} ${osVersion || ''}`

  return <Text value={text} />
}
