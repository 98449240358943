import axios from 'axios'

const BASE_URL = process.env.BASE_URL

// TODO: Use something lighter than axios
export const client = axios.create({
  baseURL: BASE_URL,
  // Only throw on 500
  validateStatus: status => status != 500,
})
