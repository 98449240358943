import * as React from 'react'
import { CategoriesList } from './CategoriesList'
import { categoryService } from '/services/category'
import * as Yup from 'yup'

const CategorySchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Digite um nome.'),
  tutorialUrl: Yup.string(),
  tutorialDescription: Yup.string(),
  automaticRedeemUrl: Yup.string(),
})

export class CategoriesListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
    }
  }

  onCreate = async values => {
    const categoryId = await categoryService.createCategory(values)

    if (categoryId) {
      const item = { ...values, id: categoryId }
      return { ok: true, msg: 'Categoria criada com sucesso!', item }
    } else {
      return { ok: false, msg: 'Ocorreu um erro ao criar a categoria.' }
    }
  }

  onEdit = async values => {
    await categoryService.editCategory(values)

    return {
      ok: true,
      msg: `Categoria editada com sucesso!`,
      item: values,
    }
  }

  onDelete = async category => {
    const status = await categoryService.deleteCategory(category)

    if (status === 200) {
      return {
        ok: true,
        msg: `Categoria desabilitada com sucesso.`,
      }
    } else {
      return {
        ok: false,
        msg: 'Ocorreu algum erro. Tente novamente em alguns segundos',
      }
    }
  }

  loadCategories = async ({ page = 1, pageSize = 20, query } = {}) => {
    if (query === undefined) {
      const { categories, total } = await categoryService.listByPage(
        page,
        pageSize,
      )
      return { page, pageSize, data: categories, totalCount: total }
    }

    const { categories, total } = await categoryService.search(query, {
      page,
      pageSize,
    })

    return { page, pageSize, data: categories, totalCount: total }
  }

  render() {
    return (
      <CategoriesList
        loadCategories={this.loadCategories}
        onCreate={this.onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        schema={CategorySchema}
      />
    )
  }
}
