import * as React from 'react'
import './RightContent.less'

export class RightContent extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div style={{float: 'right', marginRight: '24px'}}>
        {this.props.children}
      </div>
    )
  }
}
