import * as React from 'react'
import { Form, Input, InputNumber, Typography, Checkbox, Row, Col } from 'antd'

import './StoreDeliveryRuleForm.less'

const { Item } = Form
const { Text } = Typography

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

/* formType is one of 'create', 'edit' */
export function StoreDeliveryRuleForm({ formType, formikProps }) {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formikProps

  const formLayout = {
    labelAlign: 'left',
    labelCol: {
      md: { span: 12 },
    },
    wrapperCol: {
      md: { span: 10 },
    },
  }

  return (
    <Form {...formLayout} onSubmit={handleSubmit} id='delivery-rule-form'>
      <Row>
        <Item>
          {formType === 'edit' && (
            <Input
              disabled
              name='id'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id}
              size='small'
            />
          )}
        </Item>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Text strong style={{ display: 'inline-block', marginBottom: 20 }}>
            Regras Loja
          </Text>
          <Item label='Ativa' {...fromFormikErrors('active', errors, touched)}>
            <Checkbox
              name='active'
              onBlur={handleBlur}
              onClick={handleChange}
              checked={values.active}
            />
          </Item>
          <Item
            label='Número máximo de compras no período de 14 dias'
            {...fromFormikErrors('maxValue', errors, touched)}
          >
            <Input
              name='maxOrdersPerPeriod'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maxOrdersPerPeriod}
              size='small'
            />
          </Item>
          <Item
            label='Valor máximo'
            {...fromFormikErrors('maxValue', errors, touched)}
          >
            <Input
              name='maxValue'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maxValue}
              size='small'
            />
          </Item>
          <Item
            label='Número máximo de itens'
            {...fromFormikErrors('maxItems', errors, touched)}
          >
            <InputNumber
              name='maxItems'
              value={values.maxItems}
              min={0}
              max={100}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('maxItems', value)
              }}
              size='small'
            />
          </Item>
        </Col>
      </Row>
    </Form>
  )
}
