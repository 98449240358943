import * as React from 'react'
import { Button, Card, Icon, Modal, Table } from 'antd'
import { DataTable } from '/components/DataTable'
import { EventForm } from '/components/EventForm'
import { FormattedDate } from '../Misc/FormattedDate'
import { Checkmark } from '../Misc/Checkmark'

function makeInitialValues(event) {
  const initialValues = {
    id: event ? event.id : '',
    name: event ? event.name : '',
    date: event ? event.date : '',
    winnersQuantity: event ? event.winnersQuantity : '',
    enabled: event ? event.enabled : false,
  }

  return initialValues
}

export class EventsList extends React.Component {
  constructor(props) {
    super(props)
  }

  header = () => {
    return {
      id: { title: 'ID' },
      name: {
        title: 'Nome',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      date: {
        title: 'Data',
        align: 'center',
        render: date => <FormattedDate date={new Date(date)} />,
      },
      winnersQuantity: {
        title: 'Nº de vencedores',
        align: 'center',
      },
      enabled: {
        title: 'Ativado',
        align: 'center',
        render: enabled => <Checkmark checked={enabled} />,
      },
      lastWinners: {
        title: 'Ganhadores',
        align: 'center',
        render: (_, lastWinners) => (
          <Button
            onClick={async () => {
              this.showAccesses(lastWinners.id)
            }}
          >
            <Icon type='trophy' />
          </Button>
        ),
      },
    }
  }

  showAccesses = async eventId => {
    const { data } = await this.props.loadLastWinners(eventId)

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Nome do cliente',
        dataIndex: 'customer.name',
        key: 'customer.name',
      },
      {
        title: 'CPF do cliente',
        dataIndex: 'customer.cpf',
        key: 'customer.cpf',
      },
      {
        title: 'Email do cliente',
        dataIndex: 'customer.email',
        key: 'customer.email',
      },
      {
        title: 'Prêmio',
        dataIndex: 'award',
        key: 'award',
      },
    ]

    Modal.info({
      style: { minWidth: '1000px' },
      title: 'Ganhadores',
      icon: false,
      content: (
        <>
          <Table
            rowKey={data.id}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </>
      ),
      onOk() {},
    })
  }

  render() {
    const { schema, onEdit, onCreate, onDelete, loadEvents } = this.props
    return (
      <Card title='Eventos' bordered={false}>
        <DataTable
          searchText={'Busque pelo ID do evento'}
          dataLoader={loadEvents}
          header={this.header()}
          delete={{ onDelete }}
          edit={{
            type: 'modal',
            text: 'Editar Evento',
            formik: {
              initialValues: makeInitialValues,
              onSubmit: onEdit,
              schema,
            },
            render: (formikProps, category) => {
              return (
                <EventForm
                  formikProps={formikProps}
                  type='edit'
                  category={category}
                />
              )
            },
          }}
          create={{
            type: 'modal',
            text: 'Criar um evento',
            formik: {
              initialValues: makeInitialValues,
              onSubmit: onCreate,
              schema,
            },
            render: formikProps => {
              return <EventForm formikProps={formikProps} type='create' />
            },
          }}
        />
      </Card>
    )
  }
}
