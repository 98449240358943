import * as React from 'react'
import {Card, Typography} from 'antd'
import {DataTable} from '/components/DataTable'
import {TemplateForm} from '/components/TemplateForm'
import {templateService} from '/services'
import * as Yup from 'yup'

const TemplateSchema = Yup.object().shape({
  name: Yup.string().required('Digite o nome do template.'),
  content: Yup.string().required('Digite o conteúdo.'),
  subject: Yup.string().required('Digite um assunto.'),
})

const {Text} = Typography

function makeInitialValues(template) {
  let initialValues = {
    name: '',
    subject: '',
    content: '',
    isDefault: false,
  }
  if (template) {
    initialValues = {...template}
  }
  return initialValues
}

async function loadTemplates({page = 1, pageSize = 20} = {}) {
  const templates = await templateService.listTemplates()
  return {page, pageSize, data: templates, totalCount: templates.length}
}

async function createTemplate(item, data) {
  const templateId = await templateService.createTemplate(item)
  const newItem = {...item, id: templateId}

  if (!item.isDefault) {
    return {ok: true, msg: 'Template criado com sucesso!', item: newItem}
  }

  let newData = [...data]
  const oldDefaultTemplateIndex = newData.findIndex(t => t.isDefault)

  if (oldDefaultTemplateIndex >= 0) {
    newData[oldDefaultTemplateIndex].isDefault = false
  }

  newData = [newItem, ...newData]

  return {ok: true, msg: 'Template criado com sucesso!', data: newData}
}

async function deleteTemplate(item) {
  const {status} = await templateService.deleteById(item.id)

  if (status == 200) {
    return {ok: true, msg: 'Template deletado com sucesso.'}
  } else {
    return {ok: false, msg: 'Não foi possível deletar o template.'}
  }
}

async function onEditTemplate(values) {
  await templateService.editTemplate(values)

  return {
    ok: true,
    msg: `Template editado com sucesso!`,
    item: values,
  }
}
export function TemplateList() {
  return (
    <Card title='Templates' bordered={false}>
      <DataTable
        showSearch={false}
        dataLoader={loadTemplates}
        header={header}
        delete={{
          onDelete: deleteTemplate,
        }}
        edit={{
          type: 'modal',
          text: 'Editar template',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: onEditTemplate,
            schema: TemplateSchema,
          },
          render: (formikProps, template) => {
            return (
              <TemplateForm
                formikProps={formikProps}
                type='edit'
                template={template}
              />
            )
          },
        }}
        create={{
          type: 'modal',
          text: 'Criar template',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: createTemplate,
            schema: TemplateSchema,
          },
          render: formikProps => {
            return <TemplateForm formikProps={formikProps} />
          },
        }}
      />
    </Card>
  )
}

const header = {
  id: {title: 'ID'},
  name: {
    title: 'Nome',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  subject: {title: 'Assunto'},
  content: {
    title: 'Conteúdo',
    render: content => {
      const newText = content.slice(0, 50) + '...'
      return <Text>{newText}</Text>
    },
  },
  isDefault: {
    title: 'Padrão',
    render: isDefault => {
      if (!isDefault) {
        return <Text>Não</Text>
      }
      return <Text style={{fontWeight: 'bold'}}>Sim</Text>
    },
  },
}
