import * as React from 'react'
import { message } from 'antd'
import { DeliverableCreateSingle } from './DeliverableCreateSingle'
import { deliverableService } from '/services/deliverable'

export class DeliverableCreateSingleContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: '',
      generatedDeliverable: null,
      autoDelivery: false,
    }
  }

  handleSmsAuthChange = e => {
    this.setState({
      smsAuthEnabled: e.target.checked,
    })
  }

  handleAutoDelivery = e => {
    this.setState({
      autoDelivery: e.target.checked,
    })
  }

  handleContentChange = e => {
    this.setState({
      content: e.target.value,
    })
  }

  handleSubmit = async () => {
    if (this.state.content == '') {
      message.warning('O conteúdo do entregável não pode ser vazio!')
      return
    }

    const deliverable = {
      content: this.state.content,
      productId: this.props.selectedProduct,
      smsAuthEnabled: this.state.smsAuthEnabled,
      autoDelivery: this.state.autoDelivery,
    }

    const { status, data } = await deliverableService.create([deliverable])
    if (status == 200) {
      message.success('Entregável gerado com sucesso!')
      this.setState({
        ...this.state,
        generatedDeliverable: data[0],
      })
    } else {
      message.error('Ocorreu um erro ao gerar o entregável.')
    }
  }

  render() {
    return (
      <DeliverableCreateSingle
        {...this.props}
        content={this.state.content}
        smsAuthEnabled={this.state.smsAuthEnabled}
        autoDelivery={this.state.autoDelivery}
        generatedDeliverable={this.state.generatedDeliverable}
        onContentChange={this.handleContentChange}
        onSmsAuthChange={this.handleSmsAuthChange}
        onAutoDelivery={this.handleAutoDelivery}
        onSubmit={this.handleSubmit}
      />
    )
  }
}
