import * as React from 'react'
import { Form, Input, Icon } from 'antd'

const { Item } = Form

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

export class CategoryForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { type, formikProps } = this.props
    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
    } = formikProps

    return (
      <Form style={{ maxWidth: '400px' }} onSubmit={handleSubmit}>
        <Item>
          {type === 'edit' && (
            <Input
              disabled
              name='id'
              prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id}
            />
          )}
        </Item>
        <Item {...fromFormikErrors('name', errors, touched)}>
          <Input
            name='name'
            placeholder='Nome'
            prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
        </Item>
        <Item {...fromFormikErrors('tutorialUrl', errors, touched)}>
          <Input
            name='tutorialUrl'
            placeholder='URL para o Tutorial'
            prefix={
              <Icon type='question' style={{ color: 'rgba(0,0,0,.25)' }} />
            }
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.tutorialUrl}
          />
        </Item>
        <Item {...fromFormikErrors('tutorialDescription', errors, touched)}>
          <Input
            name='tutorialDescription'
            placeholder='Descrição do tutorial'
            prefix={
              <Icon type='question' style={{ color: 'rgba(0,0,0,.25)' }} />
            }
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.tutorialDescription}
          />
        </Item>
        <Item {...fromFormikErrors('automaticRedeemUrl', errors, touched)}>
          <Input
            name='automaticRedeemUrl'
            placeholder='URL para resgate automático'
            prefix={
              <Icon type='question' style={{ color: 'rgba(0,0,0,.25)' }} />
            }
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.automaticRedeemUrl}
          />
        </Item>
      </Form>
    )
  }
}
