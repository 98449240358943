import * as React from 'react'
import { message } from 'antd'
import { DeliveriesCreate } from './DeliveriesCreate'
import { validateEmail } from '/utils/validator'
import { productService, deliveryService, templateService } from '/services'

const getInitialState = () => ({
  email: '',
  order: {
    id: '',
    source: 'EZ',
  },
  amount: 1,
  productId: null,
  submitting: false,
  templates: [],
  chosenTemplateId: null,
})

export class DeliveriesCreateContainer extends React.Component {
  state = getInitialState()

  onAmountChange = e => {
    if (!e.target.value) {
      return
    }

    const amount = parseInt(e.target.value)
    this.setState({
      amount,
    })
  }

  onOrderChange = e => {
    this.setState({
      order: {
        id: e.target.value,
      },
    })
  }

  onEmailChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  onProductChange = async productId => {
    this.setState({
      productId,
    })
  }

  onTemplateChange = async templateId => {
    this.setState(prevState => ({
      ...prevState,
      chosenTemplateId: templateId,
    }))
  }

  onSubmit = async () => {
    const { order, amount, email, productId, chosenTemplateId } = this.state

    if (typeof amount !== 'number' || amount < 1 || amount > 100) {
      message.warning('A quantidade deve ser um valor de 1 a 100')
      return
    }

    if (!validateEmail(email)) {
      message.warning('Verifique o e-mail novamente.')
      return
    }

    this.setState({
      submitting: true,
    })

    const { data, status } = await deliveryService.deliver([
      {
        order,
        templateId: chosenTemplateId,
        customer: {
          email,
        },
        products: [
          {
            amount,
            id: productId,
          },
        ],
        imediateDelivery: true,
      },
    ])

    if (status == 200) {
      message.success('Pedido entregue com sucesso!')
    }
    if (status == 400) {
      message.error('Requisição inválida. Entre em contato com o suporte.')
    }

    if (status == 406) {
      if (data.code == 'NotEnoughStock') {
        message.warning('Sem estoque disponivel.')
      }
      if (data.code == 'AlreadyDelivered') {
        message.warning('Pedido já entregue!')
      }
      if (data.code == 'MissingOrderId') {
        message.warning('Forneça o número do pedido.')
      }
    }

    this.setState({
      submitting: false,
    })
  }

  searchProducts = async query => {
    const { products } = await productService.search(query)

    return products.map(prod => ({
      text: prod.name,
      value: prod.id,
    }))
  }

  async componentDidMount() {
    const templates = await templateService.listTemplates()
    const defaultTemplate = templates.find(t => t.isDefault)

    this.setState({
      templates,
      chosenTemplateId: defaultTemplate ? defaultTemplate.id : null,
    })
  }

  render() {
    return (
      <DeliveriesCreate
        templates={this.state.templates}
        chosenTemplateId={this.state.chosenTemplateId}
        email={this.state.email}
        orderId={this.state.order.id}
        amount={this.state.amount}
        submitting={this.state.submitting}
        productId={this.state.productId}
        productSearch={this.searchProducts}
        onEmailChange={this.onEmailChange}
        onOrderChange={this.onOrderChange}
        onAmountChange={this.onAmountChange}
        onProductChange={this.onProductChange}
        onTemplateChange={this.onTemplateChange}
        onSubmit={this.onSubmit}
      />
    )
  }
}
