/**
 * @module utils/promises
 * This module exposes general promise utility functions.
 */

/**
 * A "lazy promise" is used here to refer to a function that returns
 * a Promise when called, i.e., functions of the form `(...args) => new Promise(...)`
 * LazyPromise
 * @function
 * @name LazyPromise
 * @returns {Promise}
 */

/**
 * @description Returns a Promise that takes `ms` miliseconds to resolve
 * @param ms {number} Number of miliseconds
 * returns {Promise}
 */
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * @description Returns a lazy promise that takes at least `ms` miliseconds to resolve
 * with lazyPromise's value.
 * @param lazyPromise {LazyPromise} the lazy promise to be wrapped
 * @param ms {number} Number of miliseconds
 * @returns {LazyPromise} The wrapped lazyPromise
 */
export function delayed(lazyPromise, ms) {
  return async (...args) => {
    const [result, _] = await Promise.all([lazyPromise(...args), sleep(ms)])
    return result
  }
}
