import React, {useState} from 'react'
import {Button, Modal, message, Card, Typography} from 'antd'
import {DataTable} from '/components/DataTable'
import {ProductForm} from '/components/ProductForm'
import {sleep} from '/utils/promises'
import {logService} from '/services'

const {Text} = Typography

export function LogList({loadLogs}) {
  return (
    <Card title='Logs' bordered={false}>
      <DataTable
        showSearch={true}
        searchText={'Busque por entregavel, produto, categoria...'}
        dataLoader={loadLogs}
        header={header}
      />
    </Card>
  )
}

const header = {
  id: {title: 'ID'},
  action: {
    title: 'Ação',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  timestamp: {
    title: 'Data',
    render: timestampStr => {
      const text = new Date(timestampStr).toLocaleString()
      return <Text>{text}</Text>
    },
  },
  metadata: {
    title: 'Dados',
    render: metadata => (
      <Button onClick={() => showMetadata(metadata)}>Ver dados</Button>
    ),
    sorter: (a, b) => a.value - b.value,
  },
  userEmail: {
    title: 'Usuário',
    render: text => <Text>{text !== null ? text : 'Sem e-mail'}</Text>,
  },
}

function showMetadata(metadata) {
  Modal.info({
    title: 'Dados',
    content: <pre>{JSON.stringify(metadata, null, 2)}</pre>,
    onOk() {},
  })
}
