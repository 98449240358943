import * as React from 'react'
import { message } from 'antd'
import { orderService } from '/services'
import { OrderList } from './OrderList'
import { MercadoLivreAuth } from '/components/MercadoLivreAuth'
export class OrderListContainer extends React.Component {
  loadOrders = async ({ page = 1, pageSize = 20, query, filterObj } = {}) => {
    const data = await orderService.list({
      page,
      pageSize,
      query,
      filters: filterObj,
    })
    const { orders, total } = data
    return { page, pageSize, data: orders, totalCount: total }
  }

  searchOrders = async query => {
    const result = await orderService.search(query)
    return result.products.map(p => ({
      text: p.name,
      value: p.id,
    }))
  }

  editOrder = async order => {
    const { status, paymentStatus, deliveryStatus, customerNotes } = order

    const response = await orderService.edit(order.id, {
      status,
      paymentStatus,
      deliveryStatus,
      customerNotes: customerNotes.notes,
    })

    const { data } = response
    const success = response.status === 200
    let message = ''

    if (success) {
      message = 'Pedido alterado com sucesso!'
    } else if (data.code == 'NoOrderFound') {
      message = 'Não foi possível encontrar o pedido relacionado'
    } else if (data.code == 'NoCustomerDocumentFound') {
      message = 'Não foi possível encontrar o CPF do cliente'
    } else if (data.code == 'NoLogsEdited') {
      message = 'Não foi possível atualizar os dados'
    } else {
      message = 'Não foi possível alterar o pedido'
    }

    return {
      ok: success,
      msg: message,
      item: order,
    }
  }

  deliveryOrder = async orderId => {
    const deliveryId = await orderService.deliveryOrder(orderId)
    if (deliveryId) {
      message.success('Entrega realizada com sucesso!')
    } else {
      message.error('Não foi possível realizar a entrega')
    }
  }

  requestDataAnalysis = async orderId => {
    const success = await orderService.requestDataAnalysis(orderId)
    if (success) {
      message.success('Dados solicitados ao cliente')
    } else {
      message.error('Não foi possível realizar esta operação')
    }
  }

  mlQualification = async () => {
    const response = await orderService.mlQualification()

    if (response.status == 200) {
      message.success(
        'Qualificação iniciada. Será enviado um e-mail quando o processo for finalizado',
      )
    } else if (response.status == 404) {
      message.warn('Não foram encontrados pedidos para qualificar')
    } else {
      message.error('Não foi possível realizar esta operação')
    }
  }

  batchUpdate = async data => {
    const { orderStatus, deliveryStatus, paymentStatus } = data.statuses

    if (orderStatus === '' && deliveryStatus === '' && paymentStatus === '') {
      return message.warn(
        'Nenhum valor alterado, os pedidos não serão editados',
      )
    }

    const { status } = await orderService.batchUpdate(data)

    if (status == 200) {
      message.success('Pedidos alterados com sucesso!')
    } else if (status == 404) {
      message.warn('Nenhum pedido foi selecionado')
    } else {
      message.error('Não foi possível realizar esta operação')
    }
  }

  render() {
    const { user } = this.props

    return (
      <div>
        {user.role == 'superadmin' && <MercadoLivreAuth />}
        <OrderList
          {...this.props}
          editOrder={this.editOrder}
          deliveryOrder={this.deliveryOrder}
          requestDataAnalysis={this.requestDataAnalysis}
          loadOrders={this.loadOrders}
          searchOrders={this.searchOrders}
          mlQualification={this.mlQualification}
          userRole={user.role}
          batchUpdate={this.batchUpdate}
        />
      </div>
    )
  }
}
