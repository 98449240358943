import * as React from 'react'
import { render } from 'react-dom'
import { App } from '/components/App'
import { message } from 'antd'

window.addEventListener('unhandledrejection', event => {
  console.log(event.reason)
  event.preventDefault()
  message.error('Ocorreu algum erro. Tente novamente em alguns minutos.')
})

render(<App />, document.getElementById('root'))
