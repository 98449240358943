import * as React from 'react'
import {Card, message} from 'antd'
import {UserCreate} from './UserCreate'
import {sessionStore} from '/stores/SessionStore'
import {client} from '/utils/http-client'

export class UserCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <UserCreate
        data={this.state}
        onDataChange={this.onDataChange}
        onSubmit={this.onSubmit}
      />
    )
  }

  onDataChange = e => {
    let newState = {}
    if (e.target) {
      let value = e.target.value
      newState[e.target.name] = value
    } else {
      let value = e
      newState['role'] = value
    }
    newState = {...this.state, ...newState}
    this.setState(newState)
  }

  onSubmit = async e => {
    e.preventDefault()

    const data = Object.assign({}, this.state)
    const {status} = await client.post('/users', data, {
      headers: {'x-access-token': sessionStore.getToken()},
    })
    if (status == 200) {
      message.success('Usuário criado com sucesso!')
    }
    if (status == 400) {
      message.error('Verifique os dados novamente.')
    }
    if (status == 401) {
      message.error('Faça login para continuar.')
    }
  }
}
