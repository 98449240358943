import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'
import qs from 'query-string'

export const deliveryService = {
  async listByPage(page, pageSize) {
    const { data } = await client.get(
      `/delivery?page=${page}&pageSize=${pageSize}`,
      { headers: { 'x-access-token': sessionStore.getToken() } },
    )

    return {
      total: data.total,
      deliveries: data.deliveries,
    }
  },

  async listDeliveryItems(delivery) {
    const { data } = await client.get(`/delivery/${delivery.id}/items`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async deliver(recipients) {
    const { data, status } = await client.post('/delivery', recipients, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return { data, status }
  },

  async search(query, { page, pageSize } = {}) {
    const params = qs.stringify({
      query,
      page,
      pageSize,
    })

    const { data } = await client.get(`/delivery/search?${params}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async editDeliveryBundle(pin, { smsAuthEnabled, isBlocked, isGift }) {
    const { status } = await client.patch(
      `/delivery-bundle/${pin}`,
      { smsAuthEnabled, isBlocked, isGift },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return status === 200
  },
}
