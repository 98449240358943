import * as React from 'react'
import {
  List,
  Card,
  Icon,
  Popconfirm,
  Button,
  message,
  notification,
} from 'antd'
import { sessionStore } from '/stores/SessionStore'
import { categoryService, productService } from '/services'
import { ProductList } from './ProductList'
import { format } from 'date-fns'
import { download } from '/utils/download'
import { orderService } from '/services/order'
const { Item } = List
import * as Yup from 'yup'

const ProductSchema = Yup.object().shape({
  // id: Yup.string(),
  name: Yup.string().required('Digite um nome.'),
  description: Yup.string().required('Digite uma descrição.'),
  value: Yup.number()
    .typeError('O valor deve ser um número.')
    .positive('O valor deve ser positivo.')
    .required('Digite o valor.'),
  type: Yup.string().required('Informe o tipo do produto.'),
  ezId: Yup.string(),
  mlId: Yup.string(),
  idBlackhawk: Yup.string(),
  packItems: Yup.array(),
  categoryId: Yup.string().nullable(),
  autoDelivery: Yup.boolean(),
})

export class ProductListContainer extends React.Component {
  state = {
    startDate: '',
    endDate: '',
  }

  onDeleteProduct = async id => {
    await productService.deleteProductById(id)
    return {
      ok: true,
      msg: `Produto desabilitado com sucesso!`,
    }
  }

  onCreateProduct = async values => {
    delete values.selectedProduct
    delete values.selectedProductAmount

    const productId = await productService.createProduct(values)

    if (productId) {
      const item = { ...values, id: productId }
      return { ok: true, msg: 'Produto criado com sucesso!', item }
    } else {
      return { ok: false, msg: 'Ocorreu um erro ao criar o produto.' }
    }
  }

  onEditProduct = async values => {
    await productService.editProduct(values)
    return {
      ok: true,
      msg: `Produto ${values.id} editado com sucesso`,
      item: values,
    }
  }

  searchCategories = async query => {
    const result = await categoryService.search(query)
    return result.categories.map(cat => ({
      text: cat.name,
      value: cat.id,
    }))
  }

  searchProducts = async query => {
    const result = await productService.search(query)
    return result.products.map(p => ({
      text: p.name,
      value: p.id,
    }))
  }

  searchHypeProducts = async query => {
    const result = await productService.listHypeCatalog()

    return result
      .filter(p => p.name.match(new RegExp(`${query}`, 'i')) || p.id == query)
      .map(p => ({
        text: p.name,
        value: p.id,
      }))
  }

  loadProducts = async ({ page = 1, pageSize = 20, query } = {}) => {
    if (query === undefined) {
      const data = await productService.listByPage(page, pageSize)
      return { page, pageSize, data: data.products, totalCount: data.total }
    }

    const { products, total } = await productService.search(query, {
      page,
      pageSize,
    })
    return { page, pageSize, data: products, totalCount: total }
  }

  onCreateBlackhawkReport = async () => {
    const { startDate, endDate } = this.state

    if (!startDate && !endDate) {
      notification.warning({
        message: 'Nenhum campo foi preenchido!',
        description:
          'Para gerar o relatório selecione pelo menos a data inicial ou final.',
      })
      return
    }

    const blackhawkTransactions = await orderService.loadBlackhawkTransactions(
      startDate,
      endDate,
    )

    let text = ''

    blackhawkTransactions.map(transaction => {
      const {
        id,
        account_id,
        order_id,
        partner_reference,
        product_id,
        status,
        timestamp,
        transaction_id,
        content,
      } = transaction

      text += `
      ID: ${id};
      Data: ${timestamp};
      Status: ${status};
      TransactionId: ${transaction_id};
      AccountId: ${account_id};
      Partner Reference: ${partner_reference};
      OrderId: ${order_id};
      ProductId: ${product_id};
      PIN: ${content};
      \n\n`
    })

    if (startDate && endDate) {
      download(`blackhawk-${startDate}--${endDate}.txt`, text)
    } else if (startDate && !endDate) {
      download(`blackhawk-${startDate}.txt`, text)
    } else {
      download(`blackhawk-${endDate}.txt`, text)
    }

    return
  }

  onCreateIncommReport = async () => {
    const { startDate, endDate } = this.state

    if (!startDate && !endDate) {
      notification.warning({
        message: 'Nenhum campo foi preenchido!',
        description:
          'Para gerar o relatório selecione pelo menos a data inicial ou final.',
      })
      return
    }

    const incommTransactions = await orderService.loadIncommTransactions(
      startDate,
      endDate,
    )

    let text = ''

    incommTransactions.map(transaction => {
      const {
        id,
        transaction_id,
        partner_reference,
        order_id,
        product_id,
        timestamp,
        content,
      } = transaction

      text += `
      ID: ${id};
      TransactionId: ${transaction_id};
      Partner Reference: ${partner_reference};
      OrderId: ${order_id};
      ProductId: ${product_id};
      Data: ${timestamp};
      PIN: ${content};
      \n\n`
    })

    if (startDate && endDate) {
      download(`incomm-${startDate}--${endDate}.txt`, text)
    } else if (startDate && !endDate) {
      download(`incomm-${startDate}.txt`, text)
    } else {
      download(`incomm-${endDate}.txt`, text)
    }

    return
  }

  onCreateSalesReport = async () => {
    const { startDate, endDate } = this.state

    if (!startDate || !endDate) {
      notification.warning({
        message: 'Preencha a data de início e término',
        description: 'Para gerar o relatório selecione a data inicial e final.',
      })
      return
    }

    const sales = await orderService.loadSalesReport(startDate, endDate)

    if (!sales) {
      return
    }

    download(`sales-${startDate}--${endDate}.csv`, sales)
    return
  }

  onChangeDate = (type, value) => {
    const initialDate = new Date(value)
    initialDate.setHours(0, 0, 0, 0)
    const date = format(initialDate, 'yyyy-MM-dd')

    if (date === '1969-12-31') {
      if (type === 'start') this.setState({ ...this.state, startDate: '' })
      if (type === 'end') this.setState({ ...this.state, endDate: '' })
    } else {
      if (type === 'start') this.setState({ ...this.state, startDate: date })
      if (type === 'end') this.setState({ ...this.state, endDate: date })
    }
  }

  render() {
    return (
      <ProductList
        {...this.props}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        loadProducts={this.loadProducts}
        onCreate={this.onCreateProduct}
        onEdit={this.onEditProduct}
        onDelete={this.onDeleteProduct}
        schema={ProductSchema}
        searchCategories={this.searchCategories}
        searchProducts={this.searchProducts}
        searchHypeProducts={this.searchHypeProducts}
        onChangeDate={this.onChangeDate}
        onCreateBlackhawkReport={this.onCreateBlackhawkReport}
        onCreateIncommReport={this.onCreateIncommReport}
        onCreateSalesReport={this.onCreateSalesReport}
      />
    )
  }
}
