import * as React from 'react'
import { Row, Col, Icon, Button, Input, Checkbox } from 'antd'
import { RemoteSelect } from '/components/Form'

export class DeliverableCreateSingle extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { fetching, searchProducts, generatedDeliverable } = this.props

    return (
      <div style={{ marginTop: '30px' }}>
        <Row>
          <Col span={6}>
            <RemoteSelect
              placeholder='Busque pelo ID ou nome do produto'
              dataSearcher={searchProducts}
              onChange={this.props.onProductChange}
            />
          </Col>
        </Row>
        <Row style={{ margin: '15px 0' }}>
          <Col span={6}>
            <Input
              placeholder='Digite aqui o conteúdo'
              value={this.props.content}
              onChange={this.props.onContentChange}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button onClick={this.props.onSubmit}>
              <Icon type='download' /> Gerar
            </Button>
            {generatedDeliverable && (
              <p style={{ marginTop: '30px' }}>{generatedDeliverable.link}</p>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}
