import * as React from 'react'
import {UserAvatar} from './UserAvatar'
import {userService} from '/services/user'

export class UserAvatarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.user.name,
      email: props.user.email
    }
  }

  render() {
    return (
      <UserAvatar {...this.props} user={this.state} logout={this.logout}/>
    )
  }

  logout = async () => {
    await userService.logout()
    this.props.logoutSuccess()
    this.props.history.push('/')
  }
}
