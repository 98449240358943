import * as React from 'react'
import { Typography } from 'antd'
import { Checkmark } from '/components/Misc'
import { DataTable } from '/components/DataTable'
import { DeliveryRuleForm } from '/components/DeliveryRuleForm'

const { Text } = Typography

function makeInitialValues(rule) {
  let initialValues = {
    id: rule ? rule.id : '',
    active: rule ? rule.active : false,
    smsAuthEnabledEz: rule ? rule.smsAuthEnabledEz : false,
    smsAuthEnabledMl: rule ? rule.smsAuthEnabledMl : false,
    maxValue: rule ? rule.maxValue : 100,
    maxItems: rule ? rule.maxItems : 2,
    deliverOnCredit: rule ? rule.deliverOnCredit : false,
    deliverOnFreightPaid: rule ? rule.deliverOnFreightPaid : false,
    minPointsTicket: rule ? rule.minPointsTicket : 0,
    minPointsCredit: rule ? rule.minPointsCredit : 0,
    minPointsBalance: rule ? rule.minPointsBalance : 0,
    smsAuthMinRegistrationTime: rule ? rule.smsAuthMinRegistrationTime : 0,
    smsAuthMinValue: rule ? rule.smsAuthMinValue : 0,
  }
  return initialValues
}

export function RulesDataTable({
  loadRules,
  onCreate,
  onEdit,
  onDelete,
  schema,
}) {
  return (
    <DataTable
      searchText={'Busque pelo titulo da regra.'}
      dataLoader={loadRules}
      header={header}
      modalSize={'1366px'}
      edit={{
        type: 'modal',
        text: 'Editar regra',
        formik: {
          initialValues: makeInitialValues,
          onSubmit: onEdit,
          schema,
        },
        render: (formikProps, rule) => {
          return (
            <DeliveryRuleForm
              formikProps={formikProps}
              formType='edit'
              rule={rule}
            />
          )
        },
      }}
      create={{
        type: 'modal',
        text: 'Criar regra',
        formik: {
          initialValues: makeInitialValues,
          onSubmit: onCreate,
          schema,
        },
        render: formikProps => {
          return (
            <DeliveryRuleForm formikProps={formikProps} formType='create' />
          )
        },
      }}
      delete={{
        text: 'Tem certeza que deseja deletar',
        onDelete: rule => onDelete(rule.id),
      }}
    />
  )
}

const header = {
  id: {
    title: 'Id',
  },
  active: {
    title: 'Ativa',
    render: active => <Checkmark checked={active} />,
  },
  smsAuthEnabledEz: {
    title: 'Validação SMS EZ',
    render: smsAuthEnabledEz => <Checkmark checked={smsAuthEnabledEz} />,
  },
  smsAuthEnabledMl: {
    title: 'Validação SMS ML',
    render: smsAuthEnabledMl => <Checkmark checked={smsAuthEnabledMl} />,
  },
  maxValue: {
    title: 'Valor máximo',
  },
  maxItems: {
    title: 'Qtd máx de itens',
  },
  deliverOnCredit: {
    title: 'Cartão de crédito',
    render: deliverOnCredit => <Checkmark checked={deliverOnCredit} />,
  },
  deliverOnFreightPaid: {
    title: 'Frete pago',
    render: deliverOnFreightPaid => (
      <Checkmark checked={deliverOnFreightPaid} />
    ),
  },
  minPointsTicket: {
    title: 'Pontuação mínima Boleto',
    render: minPointsTicket => <Text>{minPointsTicket}</Text>,
  },
  minPointsCredit: {
    title: 'Pontuação mínima Cartão',
    render: minPointsCredit => <Text>{minPointsCredit}</Text>,
  },
  minPointsBalance: {
    title: 'Pontuação mínima Saldo ML',
    render: minPointsBalance => <Text>{minPointsBalance}</Text>,
  },
}
