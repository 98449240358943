import * as React from 'react'

export function FormattedDate({date, style}) {
  let formattedDate = new Date(date)

  let monthDay = formattedDate.getDate()
  let month = formattedDate.getMonth() + 1
  const year = formattedDate.getFullYear()
  let hour = formattedDate.getHours()
  let minutes = formattedDate.getMinutes()
  let seconds = formattedDate.getSeconds()

  if (monthDay < 10) monthDay = `0${monthDay}`
  if (month < 10) month = `0${month}`
  if (hour < 10) hour = `0${hour}`
  if (minutes < 10) minutes = `0${minutes}`
  if (seconds < 10) seconds = `0${seconds}`

  formattedDate = `${monthDay}/${month}/${year} - ${hour}:${minutes}:${seconds}`

  return <span style={style}>{formattedDate}</span>
}
