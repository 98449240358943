import * as React from 'react'
import { Form, Input, Select, Icon, DatePicker } from 'antd'
import moment from 'moment'

const { Item } = Form
const { Option } = Select

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

export class EventForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { type, formikProps } = this.props
    const dateFormat = 'YYYY-MM-DD'

    const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
    } = formikProps

    return (
      <Form style={{ maxWidth: '400px' }} onSubmit={handleSubmit}>
        <Item>
          {type === 'edit' && (
            <Input
              disabled
              name='id'
              prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id}
            />
          )}
        </Item>
        <Item {...fromFormikErrors('name', errors, touched)}>
          <Input
            name='name'
            placeholder='Nome do evento'
            prefix={
              <Icon type='carry-out' style={{ color: 'rgba(0,0,0,.25)' }} />
            }
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
          />
        </Item>
        <Item {...fromFormikErrors('date', errors, touched)}>
          <DatePicker
            showTime
            name='date'
            onChange={(_, dateString) => setFieldValue('date', dateString)}
            defaultValue={null}
            value={values.date ? moment(values.date) : ''}
          />
        </Item>
        <Item {...fromFormikErrors('winnersQuantity', errors, touched)}>
          <Input
            name='winnersQuantity'
            placeholder='Insira a quantidade de vencedores'
            prefix={<Icon type='trophy' style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='number'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.winnersQuantity}
          />
        </Item>
      </Form>
    )
  }
}
