import * as React from 'react'
import { Fragment } from 'react'
import {
  Card,
  Typography,
  Icon,
  Tooltip,
  Tag,
  Popconfirm,
  Button,
  Modal,
} from 'antd'
import { StoreOrderDetails } from '/components/StoreOrderDetails'
import * as Yup from 'yup'

const { Text } = Typography

import { DataTable, FilterTypes } from '/components/DataTable'
import { FormattedDate } from '/components/Misc'
import { localTranslator } from '/utils/translator'

function makeInitialValues(order) {
  let initialValues = {
    ...order,
    customerNotes: { timestamp: null },
  }
  return initialValues
}

const EditStoreOrderSchema = Yup.object().shape({
  notes: Yup.string(),
})

export function StoreOrderList({
  loadOrders,
  deliveryOrder,
  requestDataAnalysis,
  editCustomerNotesByOrder,
  loadOrderItems,
}) {
  return (
    <Card title='Gerenciar Pedidos Loja' bordered={false}>
      <DataTable
        searchText={'Busque pelo ID do pedido ou título do anúncio'}
        dataLoader={loadOrders}
        header={header(loadOrderItems)}
        modalSize='1280px'
        edit={{
          type: 'modal',
          text: 'Editar pedido',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: editCustomerNotesByOrder,
            schema: EditStoreOrderSchema,
          },
          render: (formikProps, order) => {
            return <StoreOrderDetails formikProps={formikProps} order={order} />
          },
        }}
        extraActions={{
          render: order => {
            return (
              <Fragment>
                <Popconfirm
                  onConfirm={() => requestDataAnalysis(order.id)}
                  title='Tem certeza que deseja solicitar análise para este pedido?'
                  okText={'Sim, tenho certeza'}
                  cancelText={'Não'}
                >
                  <Tooltip title='Solicitar análise de dados'>
                    <Icon
                      type='security-scan'
                      style={{
                        fontSize: 18,
                        color: 'rgba(0, 0, 0, 0.45)',
                        marginRight: 5,
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  onConfirm={() => deliveryOrder(order.id)}
                  title='Tem certeza que deseja enviar este pedido?'
                  okText={'Sim, tenho certeza'}
                  cancelText={'Não'}
                >
                  <Tooltip title='Enviar pedido'>
                    <Icon
                      type='check'
                      style={{
                        fontSize: 18,
                        color: 'rgba(0, 0, 0, 0.45)',
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
              </Fragment>
            )
          },
        }}
        dataLoaderFilters={{
          startDate: {
            text: 'Data inicial',
            type: FilterTypes.Date,
          },
          endDate: {
            text: 'Data final',
            type: FilterTypes.Date,
          },
          sorted: {
            text: 'Ordenar por',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Mais recentes', value: 'date_desc' },
              { text: 'Menos recentes', value: 'date_asc' },
            ],
          },
          status: {
            type: FilterTypes.Select,
            text: 'Status do Pedido',
            selectOptions: [
              { text: 'Criado', value: 'created' },
              { text: 'Aprovado', value: 'approved' },
              { text: 'Cancelado', value: 'cancelled' },
            ],
          },
          paymentStatus: {
            text: 'Pagamento',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Pendente', value: 'pending' },
              { text: 'Aprovado', value: 'approved' },
              { text: 'Análise', value: 'analysis' },
              { text: 'Validado_TEL', value: 'validated_tel' },
              { text: 'Validado_DOC', value: 'validated_doc' },
              { text: 'Cancelado', value: 'cancelled' },
            ],
          },
          deliveryStatus: {
            text: 'Envio',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Pendente', value: 'pending' },
              { text: 'Enviado', value: 'sent' },
              { text: 'Entregue', value: 'delivered' },
              { text: 'Abortado', value: 'aborted' },
              { text: 'Na fila', value: 'in_queue' },
            ],
          },
          abortReason: {
            text: 'Razão da abortagem',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Frete pago', value: 'freight' },
              { text: 'Valor máximo', value: 'max_value' },
              { text: 'Número máximo de itens', value: 'max_items' },
              { text: 'Sem estoque', value: 'not_enough_stock' },
              {
                text: 'Entrega automática desabilitada',
                value: 'autodelivery_disabled',
              },
              {
                text: 'Produto com entrega automática desabilitada',
                value: 'product_autodelivery_disabled',
              },
              {
                text: 'Número máximo de compras no período',
                value: 'last_orders',
              },
            ],
          },
        }}
      />
    </Card>
  )
}

const header = loadOrderItems => {
  return {
    id: {
      title: (
        <div
          style={{
            marginLeft: '2em',
          }}
        >
          ID
        </div>
      ),
      render: id => {
        return id
      },
    },
    buyer: {
      title: 'Email do cliente',
      render: buyer => {
        return buyer.email
      },
    },
    status: {
      title: 'Status',
      align: 'center',
      render: status => {
        const tagColor =
          status == 'approved'
            ? 'green'
            : status == 'cancelled'
            ? 'red'
            : 'gray'
        return <Tag color={tagColor}>{localTranslator[status]}</Tag>
      },
    },
    amount: {
      title: 'Total de itens',
      align: 'center',
    },
    totalValue: {
      title: 'Valor do pedido',
      align: 'center',
      render: totalValue => {
        return totalValue && 'R$' + totalValue
      },
    },
    recipients: {
      title: 'Itens enviados',
      align: 'center',
      render: (_, record) => (
        <Button
          onClick={async () => {
            const items = await loadOrderItems(record)
            showItems(items)
          }}
        >
          Ver itens
        </Button>
      ),
    },
    paymentStatus: {
      title: 'Pagamento',
      align: 'center',
      render: paymentStatus => {
        const tagColor = paymentStatus.match(/validated_doc|validated_tel/)
          ? 'blue'
          : paymentStatus == 'approved'
          ? 'green'
          : paymentStatus == 'analysis'
          ? 'orange'
          : paymentStatus == 'cancelled'
          ? 'red'
          : 'gray'
        return <Tag color={tagColor}>{localTranslator[paymentStatus]}</Tag>
      },
    },
    deliveryStatus: {
      title: 'Envio',
      align: 'center',
      render: (status, item) => {
        const tagColor = status.match(/sent|delivered/)
          ? 'green'
          : status == 'aborted'
          ? 'red'
          : 'blue'
        if (status == 'aborted' && item.abortReason) {
          const [abortReason] = item.abortReason.split(':')

          return (
            <Fragment>
              <Tag color={tagColor}>{`${localTranslator[status]}`}</Tag>
              <Text>{localTranslator[abortReason]}</Text>
            </Fragment>
          )
        } else {
          return <Tag color={tagColor}>{localTranslator[status]}</Tag>
        }
      },
    },
    isPrime: {
      title: 'Prime?',
      align: 'center',
      render: isPrime => {
        return isPrime ? (
          <Tag color='blue'>Sim</Tag>
        ) : isPrime === false ? (
          <Tag color='gray'>Não</Tag>
        ) : (
          ''
        )
      },
    },
    isVip: {
      title: 'VIP?',
      align: 'center',
      render: isVip => {
        return isVip ? (
          <Tag color='blue'>Sim</Tag>
        ) : isVip === false ? (
          <Tag color='gray'>Não</Tag>
        ) : (
          ''
        )
      },
    },
    createdAt: {
      title: 'Data de criação',
      align: 'center',
      render: date => {
        return <FormattedDate date={date} />
      },
    },
  }
}

function showItems(recipients) {
  Modal.info({
    style: { minWidth: '700px' },
    title: 'Itens do pedido',
    content: (
      <div>
        {recipients.map(recipient => {
          return (
            <div className='product-entry' style={{ fontSize: '16px' }}>
              <Text strong>{recipient['Product.name']}</Text>
              <br />
              {recipient.amount ? (
                <Text>Quantidade: {recipient.amount}</Text>
              ) : null}
              <br />
              {!isNaN(recipient.unitaryValue) ? (
                <Text>Valor unitário: R${recipient.unitaryValue}</Text>
              ) : null}
              <Text>
                <br />
                {!isNaN(recipient.unitaryValue)
                  ? 'Valor total: R$' +
                    (recipient.unitaryValue * recipient.amount).toFixed(2)
                  : null}
              </Text>
              <br />
            </div>
          )
        })}
      </div>
    ),
    onOk() {},
  })
}
