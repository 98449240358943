import * as React from 'react'
import { Card, Form, Spin, Input, Icon, Button, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { DeliveryRuleForm } from '/components/DeliveryRuleForm'

const { Item } = Form

export class DeliveryRuleEdit extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Card title='Editar regra' bordered={false}>
        {!this.props.loading && (
          <DeliveryRuleForm
            data={this.props.data}
            onSubmit={this.props.onSubmit}
            onDataChange={this.props.onDataChange}
          />
        )}
        {this.props.loading && (
          <div
            className='example'
            style={{ textAlign: 'center', padding: '50px' }}
          >
            <Spin spinning={this.props.loading} size='large' />
          </div>
        )}
      </Card>
    )
  }
}
