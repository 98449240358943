import * as React from 'react'
import {Route} from 'react-router-dom'
import {DeliveriesCreateContainer} from '/components/DeliveriesCreate'
import {DeliveriesListContainer} from '/components/DeliveriesList'

export class DeliveriesPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {match} = this.props
    return (
      <div>
        <Route
          exact
          path={`${match.path}`}
          render={props => <DeliveriesListContainer {...this.props} />}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={props => <DeliveriesCreateContainer {...this.props} />}
        />
      </div>
    )
  }
}
