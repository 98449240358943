import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'
import qs from 'query-string'

export const productService = {
  async listByPage(page, pageSize) {
    const { data } = await client.get(
      `/products?page=${page}&pageSize=${pageSize}`,
      { headers: { 'x-access-token': sessionStore.getToken() } },
    )

    return {
      total: data.total,
      products: data.products,
    }
  },

  async createProduct(product) {
    const { data } = await client.post('/products', product, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data.productId
  },

  async editProduct(product) {
    const productCopy = { ...product }
    const id = productCopy.id
    delete productCopy.id

    await client.patch(`/products/${id}`, product, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })
  },

  async deleteProductById(id) {
    await client.delete(`/products/${id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })
  },

  async search(idOrName, { page, pageSize } = {}) {
    const params = qs.stringify({
      query: idOrName,
      page,
      pageSize,
    })

    const { data } = await client.get(`/products/search?${params}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async listHypeCatalog() {
    const { data } = await client.get(`/products/hype/catalog`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async listDeliverablesById(id) {
    const { data, status } = await client.get(`/products/${id}/deliverables`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },
}
