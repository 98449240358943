import * as React from 'react'
import { DeliveryRuleEdit } from './DeliveryRuleEdit'
import { sessionStore } from '/stores/SessionStore'
import { client } from '/utils/http-client'

export class DeliveryRuleEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rule: {},
      fetchingData: true,
    }
  }

  onDataChange = e => {
    let newState = {
      rule: {
        ...this.state.rule,
      },
      fetchingData: this.state.fetchingData,
    }

    if (e.target) {
      let value = e.target.value
      newState.rule[e.target.rule] = value
    } else {
      let value = e
      newState.rule['role'] = value
    }

    this.setState(newState)
  }

  onSubmit = async e => {
    e.preventDefault()
    const newData = Object.assign({}, this.state.rule)
    delete newData.id

    const { status } = await client.patch(
      `/delivery-rules/${this.state.rule.id}`,
      newData,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )
    if (status == 200) {
      message.success('Regra alterada com sucesso!')
    }
    if (status == 400) {
      message.error('Verifique os dados novamente.')
    }
    if (status == 401) {
      message.error('Faça login para continuar.')
    }
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id
    const { data: rule } = await client.get(`/delivery-rules/${id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })
    this.setState({ rule, fetchingData: false })
  }

  render() {
    return (
      <DeliveryRuleEdit
        data={this.state.rule}
        onDataChange={this.onDataChange}
        onSubmit={this.onSubmit}
        loading={this.state.fetchingData}
      />
    )
  }
}
