import * as React from 'react'
import {
  message,
  Select,
  Modal,
  Table,
  Icon,
  Row,
  Col,
  Button,
  Input,
  Popconfirm,
  DatePicker,
} from 'antd'
import { SPINNER_INDICATOR } from '/components/Spinner'
import { RemoteSelect } from '/components/Form'
import { delayed } from '/utils/promises'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'

function transformToColumns(
  header,
  { shouldShowEdit, shouldShowDelete, showModal, onDelete, deleteConfirm },
  extraActions,
) {
  const columns = []
  for (const [key, val] of Object.entries(header)) {
    const { title, render, sorter, align } = val
    const column = { title, key, dataIndex: key, render, sorter, align }
    columns.push(column)
  }

  columns.push({
    title: 'Ações',
    key: 'actions',
    render: (text, record) => {
      return (
        <div>
          {shouldShowEdit && (
            <a style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              <Icon
                onClick={showModal('edit', record)}
                style={{ fontSize: '18px', marginRight: '8px' }}
                type='edit'
              />
            </a>
          )}
          {shouldShowDelete && (
            <Popconfirm
              onConfirm={() => {
                onDelete(record)
              }}
              title={
                deleteConfirm || 'Tem certeza que deseja deletar este item?'
              }
              okText={'Sim, tenho certeza'}
              cancelText={'Não'}
            >
              <a style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                <Icon style={{ fontSize: '18px' }} type='delete' />
              </a>
            </Popconfirm>
          )}
          {extraActions && extraActions.render(record)}
        </div>
      )
    },
  })

  return columns
}

export const FilterTypes = Object.freeze({
  Select: Symbol('Select'),
  RemoteSelect: Symbol('RemoteSelect'),
  Date: Symbol('Date'),
})

// FIXME: DataTable currently assumes that each item in the dataSource array contains
// an `id` key. We should make key this configurable via props.
export class DataTable extends React.Component {
  constructor(props) {
    super(props)
    let columnOpts = {
      shouldShowEdit: false,
      shouldShowDelete: false,
      showModal: this.showModal,
    }

    if (props.delete !== undefined) {
      columnOpts.shouldShowDelete = true
      columnOpts.onDelete = this.wrapDelete(props.delete.onDelete)
      columnOpts.deleteConfirm = props.delete.confirmText
    }
    if (props.edit !== undefined) {
      columnOpts.shouldShowEdit = true
    }

    this.columns = transformToColumns(
      props.header,
      columnOpts,
      props.extraActions,
    )

    this.dataLoader = delayed(props.dataLoader, 750)
    this.state = {
      selectedRowKeys: [],
      loading: true,
      activeModalType: null, // 'create' | 'edit' | null,
      data: [],
      page: 1,
      pageSize: 20,
      totalCount: 0,
      filterObj: {},
    }
  }

  // type is one of 'create' | 'edit'
  // If type === 'edit', id is equal to the id of the item being edited
  wrapSubmitter = (submitter, { type, id }) => {
    const delayedSubmitter = delayed(submitter, 750)
    return async (values, actions) => {
      actions.setSubmitting(true)
      try {
        const res = await delayedSubmitter(values, this.state.data)
        this.hideModal()
        actions.setSubmitting(false)
        if (res.ok) {
          message.success(res.msg)
        } else {
          // FIXME: Show error without closing modal
          message.error(res.msg)
        }

        // Now, we need to update the underlying table
        if (res.data !== undefined) {
          // `submitter` is using the (item, data) callback form.
          // We can simply update everything
          this.setState({
            data: res.data,
          })
        } else {
          // `submitter` is using the simpler (item) callback form.
          // We can update only the returned item.
          if (type === 'create') {
            // Add the item as the first item of the table
            this.setState(prevState => ({
              data: [res.item, ...prevState.data],
            }))
          } else {
            // Find the item's index and update it in place.
            this.setState(prevState => {
              const { data } = prevState
              const newData = [...data]
              const editedItemIndex = newData.findIndex(item => item.id === id)
              newData[editedItemIndex] = {
                ...newData[editedItemIndex],
                ...res.item,
              }
              return { data: newData }
            })
          }
        }
      } catch (err) {
        actions.setSubmitting(false)
        this.hideModal()
        message.error('Ocorreu algum erro. Tente novamente em alguns segundos.')
      }
    }
  }

  wrapDelete = onDelete => {
    return async record => {
      const res = await onDelete(record)
      if (res.ok) {
        message.success(res.msg)
      } else {
        message.error(res.msg)
      }
      this.setState(prevState => {
        const { data } = prevState
        const newData = [...data]
        const deletedItemIndex = newData.findIndex(
          item => item.id === record.id,
        )
        newData.splice(deletedItemIndex, 1)
        return { data: newData }
      })
    }
  }

  handleSearch = async query => {
    const normalizedQuery = query !== '' ? query : undefined

    this.setState({
      loading: true,
      query: normalizedQuery,
    })

    const { data, totalCount, page, pageSize } = await this.dataLoader({
      page: 1,
      pageSize: 20,
      query: normalizedQuery,
      filterObj: this.state.filterObj,
    })

    this.setState({
      loading: false,
      data,
      totalCount,
      page,
      pageSize,
    })
  }

  updateFilterKey = async (key, value) => {
    const newFilterObj = { ...this.state.filterObj }
    if (value === undefined || value === '') {
      delete newFilterObj[key]
    } else {
      newFilterObj[key] = value
    }

    this.setState({
      loading: true,
      filterObj: newFilterObj,
    })

    const { data, totalCount, page, pageSize } = await this.dataLoader({
      query: this.state.query,
      page: this.state.page,
      pageSize: this.state.pageSize,
      filterObj: newFilterObj,
    })

    this.setState({ loading: false, data, totalCount, page, pageSize })
  }

  async componentDidMount() {
    const { data, totalCount, page, pageSize } = await this.dataLoader({
      filterObj: this.state.filterObj,
    })

    this.setState({
      loading: false,
      data,
      totalCount: totalCount || 0,
      page,
      pageSize,
    })
  }

  showModal = (modalType, record) => () => {
    this.setState({ activeModalType: modalType, record })
  }

  hideModal = () => {
    this.setState({ activeModalType: null })
  }

  render() {
    const {
      showSearch = true,
      searchText,
      create,
      edit,
      delete: delete_,
      dataLoaderFilters,
      modalSize,
      onlyShow = false,
    } = this.props
    const {
      loading,
      data,
      page,
      pageSize,
      totalCount,
      activeModalType,
      record,
    } = this.state

    const content = activeModalType === 'create' ? create : edit
    let submitter = null
    if (content && content.type === 'modal') {
      submitter = this.wrapSubmitter(content.formik.onSubmit, {
        type: activeModalType,
        id: record ? record.id : undefined,
      })
    }

    const filterEntries =
      dataLoaderFilters !== undefined ? Object.entries(dataLoaderFilters) : []
    const selectors = filterEntries.map(([filterKey, filterOptions]) => {
      const { type, text } = filterOptions

      if (type === FilterTypes.Select) {
        const { selectOptions } = filterOptions
        return (
          <Select
            style={{ width: 200, marginLeft: '20px' }}
            placeholder={text}
            onChange={value => this.updateFilterKey(filterKey, value)}
          >
            {selectOptions.map(f => (
              <Select.Option value={f.value} key={f.value}>
                {f.text}
              </Select.Option>
            ))}
          </Select>
        )
      } else if (type === FilterTypes.RemoteSelect) {
        const { dataSearcher } = filterOptions
        return (
          <RemoteSelect
            style={{ width: 200, marginLeft: '20px' }}
            placeholder={text}
            dataSearcher={dataSearcher}
            onChange={value => this.updateFilterKey(filterKey, value)}
          />
        )
      } else if (type === FilterTypes.Date) {
        return (
          <DatePicker
            style={{ width: 200, marginLeft: '20px' }}
            placeholder={text}
            onChange={value => {
              const date = new Date(value)
              date.setHours(0, 0, 0, 0)
              this.updateFilterKey(filterKey, date)
            }}
          />
        )
      }
    })

    return (
      <div>
        {activeModalType !== null && content && content.type === 'modal' && (
          <Formik
            initialValues={content.formik.initialValues(record)}
            validationSchema={content.formik.schema}
            onSubmit={submitter}
          >
            {formikProps => (
              <Modal
                style={{ minWidth: modalSize || '500px' }}
                title={content.text}
                visible={true}
                onCancel={this.hideModal}
                onOk={!onlyShow ? formikProps.handleSubmit : this.hideModal}
                okButtonProps={{
                  loading: formikProps.isSubmitting,
                }}
                okButtonProps={
                  !onlyShow ? { disabled: false } : { disabled: true }
                }
                okText={formikProps.isSubmitting ? 'Salvando...' : 'Salvar'}
                cancelText={!onlyShow ? 'Cancelar' : 'Voltar'}
              >
                {content.render(formikProps, record)}
              </Modal>
            )}
          </Formik>
        )}

        <Row>
          {create && create.type === 'link' && (
            <Col span={24} style={{ textAlign: 'right' }}>
              <Link to={create.link}>
                <Button
                  type='primary'
                  icon='plus'
                  style={{ marginBottom: '15px' }}
                >
                  {create.text}
                </Button>
              </Link>
            </Col>
          )}
        </Row>
        <Row style={{ margin: '40px 0' }}>
          <Col span={12}>
            <div style={{ textAlign: 'left' }}>
              {showSearch && (
                <Input.Search
                  placeholder={searchText || 'Busque o item'}
                  style={{ width: 450 }}
                  onSearch={this.handleSearch}
                />
              )}
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {filterEntries.length > 0 && selectors}
            {create && create.type === 'modal' && (
              <Button
                type='primary'
                icon='plus'
                style={{ marginBottom: '15px' }}
                onClick={this.showModal('create')}
              >
                {create.text}
              </Button>
            )}
          </Col>
        </Row>
        <React.Fragment>
          Total: <strong>{`${totalCount || 0}`}</strong>
        </React.Fragment>
        <Table
          dataSource={data && data.map(it => ({ ...it, key: it.id }))}
          size={'middle'}
          columns={this.columns}
          scroll={{ x: true }}
          pagination={{
            pageSize: pageSize,
            total: totalCount,
            current: page,
            defaultCurrent: 1,
            onChange: async (page, pageSize) => {
              this.setState({
                loading: true,
              })
              const { data, totalCount } = await this.dataLoader({
                page,
                pageSize,
                query: this.state.query,
                filterObj: this.state.filterObj,
              })
              this.setState({
                data,
                page,
                pageSize,
                totalCount,
                loading: false,
              })
            },
          }}
          loading={{
            indicator: SPINNER_INDICATOR,
            tip: 'Atualizando...',
            spinning: loading,
          }}
        />
      </div>
    )
  }
}
