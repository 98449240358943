import * as React from 'react'
import { Route } from 'react-router-dom'
import { EventsListContainer } from '/components/EventsList'

export function EventsPage(props) {
  const { match, user } = props
  return (
    <div>
      <Route
        exact
        path={match.path}
        render={props => <EventsListContainer {...props} user={user} />}
      />
    </div>
  )
}
