import * as React from 'react'
import {Route} from 'react-router-dom'
import {Row, Col, Button} from 'antd'
import {ProductListContainer} from '/components/ProductList'
import {RixtyContainer} from '/components/Rixty'

export function ProductsPage(props) {
  const {match, user} = props
  return (
    <div>
      <Route
        exact
        path={match.path}
        render={_props => <ProductListContainer {..._props} user={user} />}
      />
      <Route
        exact
        path={`${match.path}/rixty`}
        render={_props => <RixtyContainer {...props} {..._props} />}
      />
    </div>
  )
}
