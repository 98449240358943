import * as React from 'react'
import {Route} from 'react-router-dom'
import {LogListContainer} from '/components/LogList'

export function LogsPage(props) {
  const {match, user} = props
  return (
    <div>
      <Route
        exact
        path={match.path}
        render={_props => <LogListContainer {..._props} user={user} />}
      />
    </div>
  )
}
