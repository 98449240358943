import {client} from '/utils/http-client'
import {sessionStore} from '/stores/SessionStore'

export const rixtyService = {
  async placeOrderBySKU(sku, amount) {
    if (!sku || !amount) return

    const {data, status} = await client.post(
      `/products/rixty`,
      {sku, amount},
      {
        headers: {'x-access-token': sessionStore.getToken()},
      },
    )

    return {data, status}
  },
}
