export const sessionStore = {
  setToken(token) {
    localStorage.setItem('accessToken', token)
  },

  getToken() {
    return localStorage.getItem('accessToken')
  },

  clear() {
    localStorage.removeItem('accessToken')
  },
}
