import * as React from 'react'
import { Route } from 'react-router-dom'
import { StoreOrderListContainer } from '/components/StoreOrderList'

export function StoreOrdersPage(props) {
  const { match, user } = props
  return (
    <div>
      <Route
        exact
        path={match.path}
        render={_props => <StoreOrderListContainer {..._props} user={user} />}
      />
    </div>
  )
}
