import * as React from 'react'
import {
  Card,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Icon,
  Button,
  Row,
  Col,
  List,
  Checkbox,
} from 'antd'
import { Link } from 'react-router-dom'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { RemoteSelect } from '/components/Form'

const { Item } = Form
const { Option } = Select

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

/* formType is one of 'create', 'edit' */
export function ProductForm({
  onCancel,
  onSubmit,
  product,
  formType,
  searchCategories,
  searchProducts,
  searchHypeProducts,
  backLink,
  formikProps,
}) {
  const {
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValidating,
    setFieldValue,
  } = formikProps

  const packItems =
    product && product.type == 'pack' ? product.packItems : values.packItems

  let productType = 'product'
  if (product) {
    productType = product.type
  }
  if (values.type) {
    productType = values.type
  }

  return (
    <Form style={{ maxWidth: '500px' }} onSubmit={handleSubmit}>
      <Item>
        {formType === 'edit' && (
          <Input
            disabled
            name='id'
            prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.id}
          />
        )}
      </Item>
      <Item {...fromFormikErrors('ezId', errors, touched)}>
        <Input
          name='ezId'
          placeholder='ID EZ Commerce'
          prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.ezId}
        />
      </Item>
      <Item {...fromFormikErrors('mlId', errors, touched)}>
        <Input
          name='mlId'
          placeholder='ID ML'
          prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.mlId}
        />
      </Item>
      <Item {...fromFormikErrors('name', errors, touched)}>
        <Input
          name='name'
          placeholder='Nome'
          prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
        />
      </Item>
      <Item {...fromFormikErrors('description', errors, touched)}>
        <Input
          name='description'
          placeholder='Descrição'
          prefix={<Icon type='message' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description}
        />
      </Item>
      <Item {...fromFormikErrors('value', errors, touched)}>
        <Input
          name='value'
          placeholder='Valor'
          prefix={<Icon type='dollar' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.value}
        />
      </Item>
      <Item {...fromFormikErrors('costPrice', errors, touched)}>
        <Input
          name='costPrice'
          placeholder='Preço de custo'
          prefix={<Icon type='dollar' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.costPrice}
        />
      </Item>
      <Item {...fromFormikErrors('stockAlert', errors, touched)}>
        <Input
          name='stockAlert'
          placeholder='Estoque mínimo'
          prefix={<Icon type='alert' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.stockAlert}
        />
      </Item>
      <Item {...fromFormikErrors('categoryId', errors, touched)}>
        <RemoteSelect
          placeholder='Busque pelo ID ou nome da categoria'
          dataSearcher={searchCategories}
          onBlur={handleBlur}
          onChange={value => setFieldValue('categoryId', value)}
        />
      </Item>
      <Item {...fromFormikErrors('type', errors, touched)}>
        <Select
          defaultValue='product'
          value={productType || 'product'}
          onBlur={handleBlur}
          onChange={value => setFieldValue('type', value)}
        >
          <Option value='product'>Produto</Option>
          <Option value='pack'>Pacote</Option>
          <Option value='hype'>Hype</Option>
          <Option value='blackhawk'>Blackhawk</Option>
          <Option value='incomm'>Incomm</Option>
        </Select>
      </Item>
      <Item {...fromFormikErrors('autoDelivery', errors, touched)}>
        <Checkbox
          name='autoDelivery'
          onBlur={handleBlur}
          onClick={handleChange}
          checked={values.autoDelivery}
        >
          Entrega automática
        </Checkbox>
      </Item>
      {values.type == 'incomm' && (
        <Item {...fromFormikErrors('incommId', errors, touched)}>
          <Input
            name='incommId'
            placeholder='ID Incomm'
            prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.incommId}
          />
        </Item>
      )}
      {values.type == 'blackhawk' && (
        <Item {...fromFormikErrors('blackhawkId', errors, touched)}>
          <Input
            name='blackhawkId'
            placeholder='ID Blackhawk'
            prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.blackhawkId}
          />
        </Item>
      )}
      {values.type == 'hype' && (
        <Item {...fromFormikErrors('hypeId', errors, touched)}>
          <RemoteSelect
            placeholder='Busque pelo produto hype'
            dataSearcher={searchHypeProducts}
            onBlur={handleBlur}
            initialValue={values.hypeId}
            onChange={value => setFieldValue('hypeId', value)}
          />
        </Item>
      )}
      {values.type == 'pack' && (
        <Row gutter={6}>
          <h4>Adicionar itens no pacote</h4>
          <Col span={15}>
            <RemoteSelect
              placeholder='Busque pelo ID ou nome do produto'
              labelInValue={true}
              dataSearcher={searchProducts}
              onBlur={handleBlur}
              onChange={item => setFieldValue('selectedProduct', item)}
            />
          </Col>
          <Col span={6}>
            <InputNumber
              name='selectedProductAmount'
              placeholder='Quantidade'
              min={1}
              defaultValue={1}
              value={values.selectedProductAmount}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('selectedProductAmount', value)
              }}
            />
          </Col>
          <Col span={3}>
            <Button
              type='primary'
              style={{ width: '100%' }}
              onClick={() => {
                if (
                  !values.selectedProduct ||
                  !values.selectedProduct.key ||
                  !values.selectedProductAmount
                ) {
                  return
                }

                let currentProducts = values.packItems || []

                for (let product of currentProducts) {
                  if (product.productId == values.selectedProduct.key) {
                    return
                  }
                }

                const packItems = [
                  ...currentProducts,
                  {
                    productId: values.selectedProduct.key,
                    productName: values.selectedProduct.label,
                    amount: values.selectedProductAmount,
                  },
                ]

                setFieldValue('packItems', packItems)
              }}
            >
              <Icon type='plus' />
            </Button>
          </Col>

          {packItems && packItems.length > 0 && (
            <Col span={24}>
              <List
                style={{ marginTop: '16px' }}
                header={<h4>Itens</h4>}
                itemLayout='horizontal'
                bordered
                dataSource={values.packItems}
                renderItem={item => (
                  <List.Item
                    key={item.productId}
                    actions={[
                      <Icon
                        type='close-circle'
                        onClick={() => {
                          const packItems = [...values.packItems]

                          let indexToRemove = null

                          for (let i = 0; i < packItems.length; i++) {
                            if (packItems[i].productId == item.productId) {
                              indexToRemove = i
                              break
                            }
                          }

                          if (indexToRemove < 0) return

                          packItems.splice(indexToRemove, 1)
                          setFieldValue('packItems', packItems)
                        }}
                      />,
                    ]}
                  >
                    {`${item.productName} - ${item.amount}x`}
                  </List.Item>
                )}
              />
            </Col>
          )}
        </Row>
      )}
    </Form>
  )
}
