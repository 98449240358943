import * as React from 'react'
import {sessionStore} from '/stores/SessionStore'
import {userService} from '/services/user'
import {UsersList} from './UsersList'

export class UsersListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      fetchingData: true,
    }
  }

  componentDidMount = async () => {
    const users = await userService.getAll()
    this.setState({users, fetchingData: false})
  }

  onDeleteUser = async id => {
    await userService.deleteUserById(id)
    const users = this.state.users.filter(u => u.id != id)
    this.setState({users})
  }

  render() {
    return (
      <UsersList
        {...this.props}
        users={this.state.users}
        deleteUser={this.onDeleteUser}
        loading={this.state.fetchingData}
      />
    )
  }
}
