import * as React from 'react'
import { Layout } from 'antd'
import { Sidebar } from '/components/Sidebar'
import { Topbar } from '/components/Topbar'

const { Content } = Layout

export class AppContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Topbar {...this.props} user={this.props.user} />
        <Layout>
          <Sidebar match={this.props.match} user={this.props.user} />
          <Layout style={{ padding: '16px' }}>
            <Content style={{ padding: '16px', backgroundColor: 'white' }}>
              {React.cloneElement(this.props.children, {
                user: this.props.user,
              })}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}
