import * as React from 'react'
import { Form, Input, InputNumber, Typography, Checkbox, Row, Col } from 'antd'

import './DeliveryRuleForm.less'

const { Item } = Form
const { Text } = Typography

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

/* formType is one of 'create', 'edit' */
export function DeliveryRuleForm({ formType, formikProps }) {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formikProps

  const formLayout = {
    labelAlign: 'left',
    labelCol: {
      md: { span: 12 },
    },
    wrapperCol: {
      md: { span: 10 },
    },
  }

  return (
    <Form {...formLayout} onSubmit={handleSubmit} id='delivery-rule-form'>
      <Row>
        <Item>
          {formType === 'edit' && (
            <Input
              disabled
              name='id'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.id}
              size='small'
            />
          )}
        </Item>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Text strong style={{ display: 'inline-block', marginBottom: 20 }}>
            Regras ML
          </Text>
          <Item label='Ativa' {...fromFormikErrors('active', errors, touched)}>
            <Checkbox
              name='active'
              onBlur={handleBlur}
              onClick={handleChange}
              checked={values.active}
            />
          </Item>
          <Item
            label='Valor máximo'
            {...fromFormikErrors('maxValue', errors, touched)}
          >
            <Input
              name='maxValue'
              placeholder='Valor máximo'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maxValue}
              size='small'
            />
          </Item>
          <Item
            label='Número máximo de itens'
            {...fromFormikErrors('maxItems', errors, touched)}
          >
            <InputNumber
              name='maxItems'
              value={values.maxItems}
              min={0}
              max={100}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('maxItems', value)
              }}
              size='small'
            />
          </Item>
          <Item
            label='Entregar quando aprovado no cartão'
            {...fromFormikErrors('deliverOnCredit', errors, touched)}
          >
            <Checkbox
              name='deliverOnCredit'
              onBlur={handleBlur}
              onClick={handleChange}
              checked={values.deliverOnCredit}
            />
          </Item>
          <Item
            label='Entregar quando frete pago'
            {...fromFormikErrors('deliverOnFreightPaid', errors, touched)}
          >
            <Checkbox
              name='deliverOnFreightPaid'
              onBlur={handleBlur}
              onClick={handleChange}
              checked={values.deliverOnFreightPaid}
            />
          </Item>
          <Item
            label='Pontuação mínima por boleto'
            {...fromFormikErrors('minPointsTicket', errors, touched)}
          >
            <InputNumber
              name='minPointsTicket'
              value={values.minPointsTicket}
              min={0}
              max={100}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('minPointsTicket', value)
              }}
              size='small'
            />
          </Item>
          <Item
            label='Pontuação mínima por cartão'
            {...fromFormikErrors('minPointsCredit', errors, touched)}
          >
            <InputNumber
              name='minPointsCredit'
              value={values.minPointsCredit}
              min={0}
              max={100}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('minPointsCredit', value)
              }}
              size='small'
            />
          </Item>
          <Item
            label='Pontuação mínima por saldo MP'
            {...fromFormikErrors('minPointsBalance', errors, touched)}
          >
            <InputNumber
              name='minPointsBalance'
              value={values.minPointsBalance}
              min={0}
              max={100}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('minPointsBalance', value)
              }}
              size='small'
            />
          </Item>
        </Col>
        <Col span={12}>
          <Text strong style={{ display: 'inline-block', marginBottom: 20 }}>
            Regras SMS
          </Text>
          <Item
            label='Validação SMS EZ'
            {...fromFormikErrors('smsAuthEnabledEz', errors, touched)}
          >
            <Checkbox
              name='smsAuthEnabledEz'
              onBlur={handleBlur}
              onClick={handleChange}
              checked={values.smsAuthEnabledEz}
            />
          </Item>
          <Item
            label='Validação SMS ML'
            {...fromFormikErrors('smsAuthEnabledMl', errors, touched)}
          >
            <Checkbox
              name='smsAuthEnabledMl'
              onBlur={handleBlur}
              onClick={handleChange}
              checked={values.smsAuthEnabledMl}
            />
          </Item>
          <Item
            label='Valor mínimo (apenas EZ)'
            {...fromFormikErrors('smsAuthMinValue', errors, touched)}
          >
            <Input
              name='smsAuthMinValue'
              placeholder='Valor mínimo'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.smsAuthMinValue}
              size='small'
            />
          </Item>
          <Item
            label='Tempo mínimo de cadastro (apenas EZ)'
            {...fromFormikErrors('smsAuthMinRegistrationTime', errors, touched)}
          >
            <InputNumber
              name='smsAuthMinRegistrationTime'
              value={values.smsAuthMinRegistrationTime}
              min={0}
              max={100}
              onBlur={handleBlur}
              onChange={value => {
                setFieldValue('smsAuthMinRegistrationTime', value)
              }}
              size='small'
            />
            <span className='min-registration-time-unit'>mes(es)</span>
          </Item>
        </Col>
      </Row>
    </Form>
  )
}
