import * as React from 'react'
import { Card, Tabs } from 'antd'
const { TabPane } = Tabs
import { RulesDataTable } from '/components/RulesDataTable'
import { StoreRulesDataTable } from '/components/StoreRulesDataTable'

export function DeliveryRuleList({
  loadRules,
  onCreate,
  onEdit,
  onDelete,
  schema,
  loadStoreRules,
  onCreateStore,
  onEditStore,
  onDeleteStore,
  storeSchema,
}) {
  return (
    <Card title='Gerenciar Regras de Envio Automático' bordered={false}>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Regras de envio' key='1'>
          <RulesDataTable
            loadRules={loadRules}
            onCreate={onCreate}
            onEdit={onEdit}
            onDelete={onDelete}
            schema={schema}
          />
        </TabPane>
        <TabPane tab='Regras de envio loja' key='2'>
          <StoreRulesDataTable
            loadRules={loadStoreRules}
            onCreate={onCreateStore}
            onEdit={onEditStore}
            onDelete={onDeleteStore}
            schema={storeSchema}
          />
        </TabPane>
      </Tabs>
    </Card>
  )
}
