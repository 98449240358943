import * as React from 'react'
import { message } from 'antd'
import { DeliverableCreateBatch } from './DeliverableCreateBatch'
import { validateUrl } from '/utils/validator'
import { deliverableService } from '/services/deliverable'

export class DeliverableCreateBatchContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      links: [],
    }
  }

  handleSubmit = async e => {
    const deliverables = this.state.links.map(link => {
      return {
        content: link,
        productId: this.props.selectedProduct,
        smsAuthEnabled: this.state.smsAuthEnabled,
      }
    })

    const { status, data } = await deliverableService.create(deliverables)

    if (status == 200) {
      message.success('Entregáveis gerados com sucesso!')

      const links = data.map(d => {
        return d.link
      })

      const now = new Date()
      const file = new Blob([links.join('\n')], { type: 'text/plain' })
      const fileName = [
        now.getDate(),
        now.getMonth() + 1,
        now.getFullYear(),
        now.getHours(),
        now.getMinutes(),
      ].join('-')

      if (window.navigator.msSaveOrOpenBlob) {
        // Suppor for IE10+
        window.navigator.msSaveOrOpenBlob(file, new Date())
      } else {
        // Other browsers
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        // Fake a click to force the download to start
        a.click()
        setTimeout(function() {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }
    } else {
      message.error('Ocorreu um erro ao gerar os entregáveis.')
    }
  }

  handleSmsAuthChange = e => {
    this.setState({
      smsAuthEnabled: e.target.checked,
    })
  }

  handleContentChange = info => {
    if (info.fileList.length === 0) {
      this.setState({
        links: [],
      })
      return
    }

    if (this.state.links && this.state.links.length > 1) {
      message.warning('Somente um arquivo de links pode ser utilizado por vez.')
      info.fileList.splice(1)
      return
    }

    const links = []
    const reader = new FileReader()

    reader.onload = () => {
      const { result } = reader
      links.push(...result.split(/\r?\n/))

      let validated = true

      for (let i = 0; i < links.length; i++) {
        const link = links[i]

        if (link == '') {
          links.splice(i, 1)
          continue
        }

        if (link.match(/^https?:\/\//) && !validateUrl(encodeURI(link))) {
          message.error('O arquivo contém links inválidos.')
          validated = false
          break
        }
      }

      if (validated) {
        this.setState({
          ...this.state,
          links,
        })
      } else {
        info.fileList.length = 0
        this.setState({
          ...this.state,
          links: null,
        })
      }
    }

    reader.readAsText(info.file)
  }

  render() {
    return (
      <DeliverableCreateBatch
        {...this.props}
        accept={'text/plain'}
        links={this.state.links}
        smsAuthEnabled={this.state.smsAuthEnabled}
        onContentChange={this.handleContentChange}
        onSmsAuthChange={this.handleSmsAuthChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}
