import * as React from 'react'
import { message } from 'antd'
import { BlackList } from './BlackList'
import { deliverableService } from '/services'
import * as Yup from 'yup'

const BlacklistEntrySchema = Yup.object().shape({
  identifier: Yup.string().required(),
  type: Yup.string().required(),
  link: Yup.string(),
  createdAt: Yup.string(),
  reason: Yup.string(),
})

export class BlackListContainer extends React.Component {
  state = {}

  listBlacklist = async ({ query, page, pageSize }) => {
    let { total, blacklistEntries } = await deliverableService.listBlacklist({
      query,
      page,
      pageSize,
    })

    if (!page) page = 1
    if (!pageSize) pageSize = 20
    if (!total) {
      total = blacklistEntries.length
    }

    return { page, pageSize, totalCount: total, data: blacklistEntries }
  }

  onCreate = async values => {
    delete values.createdAt

    const data = await deliverableService.createBlacklistEntry(values)

    if (data && data.id) {
      return { ok: true, msg: 'Item criado com sucesso!', item: data }
    } else {
      return { ok: false, msg: 'Ocorreu um erro ao criar o item.' }
    }
  }

  onDelete = async id => {
    const status = await deliverableService.deleteBlacklistEntry(id)

    const hasSucceeded = status == 200

    return {
      ok: hasSucceeded,
      msg: hasSucceeded
        ? 'Item desabilitado com sucesso!'
        : 'Ocorreu um erro ao desabilitar o item',
    }
  }

  render() {
    return (
      <BlackList
        match={this.props.match}
        onCreate={this.onCreate}
        onDelete={this.onDelete}
        schema={BlacklistEntrySchema}
        listBlacklist={this.listBlacklist}
      />
    )
  }
}
