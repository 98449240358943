import React from 'react'
import { Icon } from 'antd'

export function Checkmark({ checked = false }) {
  return (
    <Icon
      type={checked ? 'check-circle' : 'close-circle'}
      theme={'filled'}
      style={{
        color: checked ? '#52c41a' : '#f5222d',
        fontSize: '18px',
      }}
    />
  )
}
