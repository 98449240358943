import * as React from 'react'
import { Route } from 'react-router-dom'
import { CategoriesListContainer } from '/components/CategoriesList'

export function CategoriesPage(props) {
  const { match, user } = props

  return (
    <div>
      <Route
        exact
        path={match.path}
        render={props => <CategoriesListContainer {...props} user={user} />}
      />
    </div>
  )
}
