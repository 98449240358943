import * as React from 'react'
import {Card, message} from 'antd'
import {UserEdit} from './UserEdit'
import {sessionStore} from '/stores/SessionStore'
import {client} from '/utils/http-client'

export class UserEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
      fetchingData: true,
    }
  }

  onDataChange = e => {
    let newState = {
      user: {
        ...this.state.user,
      },
      fetchingData: this.state.fetchingData,
    }

    if (e.target) {
      let value = e.target.value
      newState.user[e.target.name] = value
    } else {
      let value = e
      newState.user['role'] = value
    }

    this.setState(newState)
  }

  onSubmit = async e => {
    e.preventDefault()
    const newData = Object.assign({}, this.state.user)
    delete newData.id

    const {status} = await client.patch(
      `/users/${this.state.user.id}`,
      newData,
      {
        headers: {'x-access-token': sessionStore.getToken()},
      },
    )
    if (status == 200) {
      message.success('Usuário alterado com sucesso!')
    }
    if (status == 400) {
      message.error('Verifique os dados novamente.')
    }
    if (status == 401) {
      message.error('Faça login para continuar.')
    }
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id
    const {data: user} = await client.get(`/users/${id}`, {
      headers: {'x-access-token': sessionStore.getToken()},
    })
    this.setState({user, fetchingData: false})
  }

  render() {
    return (
      <UserEdit
        data={this.state.user}
        onDataChange={this.onDataChange}
        onSubmit={this.onSubmit}
        loading={this.state.fetchingData}
      />
    )
  }
}
