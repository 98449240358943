import * as React from 'react'
import { Card , Form, Input, Icon, Button, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { UserForm } from '/components/Form'

const {Item} = Form

export class UserCreate extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Card title='Criar usuário' bordered={false}>
        <UserForm {...this.props} />
      </Card>
    )
  }
}
