import * as React from 'react'
import { Card } from 'antd'
import { DeliveryRuleForm } from '/components/DeliveryRuleForm'

export class DeliveryRuleCreate extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Card title='Criar regra' bordered={false}>
        <DeliveryRuleForm {...this.props} />
      </Card>
    )
  }
}
