import {client} from '/utils/http-client'
import {sessionStore} from '/stores/SessionStore'

export const userService = {
  async getUser() {
    const {data} = await client.get('/users', {
      headers: {'x-access-token': sessionStore.getToken()},
    })

    return data
  },

  async getAll() {
    const {data} = await client.get('/users/all', {
      headers: {'x-access-token': sessionStore.getToken()},
    })

    return data
  },

  async deleteUserById(id) {
    const {data} = await client.delete(`/users/${id}`, {
      headers: {'x-access-token': sessionStore.getToken()},
    })
  },

  async logout() {
    const {status} = await client.delete('/sessions', {
      headers: {'x-access-token': sessionStore.getToken()},
    })
  }
}
