import * as React from 'react'
import { Form, Input, Checkbox } from 'antd'
import { RemoteSelect } from '/components/Form'
import { productService } from '/services'

const { Item } = Form

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

export function EditDeliverableForm({ formikProps }) {
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
  } = formikProps

  const searchProducts = async query => {
    const result = await productService.search(query)
    return result.products.map(p => ({
      text: p.name,
      value: p.id,
    }))
  }

  return (
    <Form style={{ maxWidth: '400px' }} onSubmit={handleSubmit}>
      <Item>
        <Input disabled name='id' value={values.id} />
      </Item>
      <Item {...fromFormikErrors('link', errors, touched)}>
        <Input disabled name='link' placeholder='Link' value={values.link} />
      </Item>
      <Item {...fromFormikErrors('productId', errors, touched)}>
        <RemoteSelect
          placeholder='Busque pelo ID ou nome do produto'
          initialValue={values.productId}
          dataSearcher={searchProducts}
          onBlur={handleBlur}
          onChange={value => setFieldValue('productId', value)}
        />
      </Item>
      <Item {...fromFormikErrors('delivered', errors, touched)}>
        <Checkbox
          name='delivered'
          onBlur={handleBlur}
          onClick={handleChange}
          checked={values.delivered}
        >
          Entregue
        </Checkbox>
      </Item>
    </Form>
  )
}
