import * as React from 'react'
import { deliveryRuleService, storeDeliveryRuleService } from '/services'
import { DeliveryRuleList } from './DeliveryRuleList'

import * as Yup from 'yup'

const DeliveryRuleSchema = Yup.object().shape({
  id: Yup.number(),
  active: Yup.boolean(),
  smsAuthEnabledEz: Yup.boolean(),
  smsAuthEnabledMl: Yup.boolean(),
  maxValue: Yup.number(),
  maxItems: Yup.number(),
  deliverOnCredit: Yup.boolean(),
  deliverOnFreightPaid: Yup.boolean(),
  minPointsTicket: Yup.number(),
  minPointsCredit: Yup.number(),
  minPointsBalance: Yup.number(),
  smsAuthMinRegistrationTime: Yup.number(),
  smsAuthMinValue: Yup.number(),
})

const StoreDeliveryRuleSchema = Yup.object().shape({
  id: Yup.number(),
  active: Yup.boolean(),
  maxValue: Yup.number(),
  maxItems: Yup.number(),
  maxOrdersPerPeriod: Yup.number(),
})

export class DeliveryRuleListContainer extends React.Component {
  onDeleteRule = async id => {
    await deliveryRuleService.deleteRuleById(id)
    return {
      ok: true,
      msg: `Regra desabilitada com sucesso!`,
    }
  }

  onCreateRule = async values => {
    delete values.selectedRule
    const ruleId = await deliveryRuleService.createRule({
      ...values,
      maxValue: parseFloat(values.maxValue),
      smsAuthMinValue: parseFloat(values.smsAuthMinValue),
    })

    if (ruleId) {
      const item = { ...values, id: ruleId }
      return { ok: true, msg: 'Regra criado com sucesso!', item }
    } else {
      return { ok: false, msg: 'Ocorreu um erro ao criar a regra.' }
    }
  }

  onEditRule = async values => {
    const status = await deliveryRuleService.editRule({
      ...values,
      maxValue: parseFloat(values.maxValue),
      smsAuthMinValue: parseFloat(values.smsAuthMinValue),
    })
    const hasSucceeded = status === 200
    const dataError = status === 400

    return {
      ok: hasSucceeded,
      item: values,
      msg: hasSucceeded
        ? `Regra alterada com sucesso`
        : dataError
        ? `Verifique os dados novamente`
        : `Ocorreu um erro. Tente novamente`,
    }
  }

  loadRules = async ({ page = 1, pageSize = 20 } = {}) => {
    const data = await deliveryRuleService.listByPage(page, pageSize)
    return { page, pageSize, data: data.rules, totalCount: data.total }
  }

  onDeleteStoreRule = async id => {
    await storeDeliveryRuleService.deleteRuleById(id)
    return {
      ok: true,
      msg: `Regra desabilitada com sucesso!`,
    }
  }

  onCreateStoreRule = async values => {
    delete values.selectedRule
    const ruleId = await storeDeliveryRuleService.createRule({
      ...values,
      maxValue: parseFloat(values.maxValue),
      maxOrdersPerPeriod: parseFloat(values.maxOrdersPerPeriod),
    })

    if (ruleId) {
      const item = { ...values, id: ruleId }
      return { ok: true, msg: 'Regra criado com sucesso!', item }
    } else {
      return { ok: false, msg: 'Ocorreu um erro ao criar a regra.' }
    }
  }

  onEditStoreRule = async values => {
    const status = await storeDeliveryRuleService.editRule({
      ...values,
      maxValue: parseFloat(values.maxValue),
      maxOrdersPerPeriod: parseFloat(values.maxOrdersPerPeriod),
    })
    const hasSucceeded = status === 200
    const dataError = status === 400

    return {
      ok: hasSucceeded,
      item: values,
      msg: hasSucceeded
        ? `Regra alterada com sucesso`
        : dataError
        ? `Verifique os dados novamente`
        : `Ocorreu um erro. Tente novamente`,
    }
  }

  loadStoreRules = async ({ page = 1, pageSize = 20 } = {}) => {
    const data = await storeDeliveryRuleService.listByPage(page, pageSize)
    return { page, pageSize, data: data.rules, totalCount: data.total }
  }

  render() {
    return (
      <DeliveryRuleList
        {...this.props}
        loadRules={this.loadRules}
        onCreate={this.onCreateRule}
        onEdit={this.onEditRule}
        onDelete={this.onDeleteRule}
        schema={DeliveryRuleSchema}
        loadStoreRules={this.loadStoreRules}
        onCreateStore={this.onCreateStoreRule}
        onEditStore={this.onEditStoreRule}
        onDeleteStore={this.onDeleteStoreRule}
        storeSchema={StoreDeliveryRuleSchema}
      />
    )
  }
}
