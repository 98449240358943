import * as React from 'react'
import {
  Icon,
  Button,
  Upload,
  Select,
  Checkbox,
  Row,
  Col,
  Spin,
  message,
} from 'antd'
import { Link } from 'react-router-dom'
import { RemoteSelect } from '/components/Form'

const { Option } = Select

export class DeliverableCreateBatch extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { accept, fetching, links, searchProducts } = this.props

    let feedbackMessage = ''
    if (links === null) {
      feedbackMessage = 'O arquivo contém links inválidos'
    } else if (links.length > 1) {
      feedbackMessage = `${links.length} entregáveis serão gerados`
    } else if (links.length == 1) {
      feedbackMessage = `1 entregável será gerado`
    }

    return (
      <React.Fragment>
        <Row style={{ marginTop: '30px' }}>
          <Col span={6}>
            <Upload
              beforeUpload={() => {
                return false
              }}
              onChange={this.props.onContentChange}
              accept={accept}
            >
              <Button>
                <Icon type='upload' /> Carregar arquivo
              </Button>
            </Upload>
          </Col>
          <Col span={6}>
            <RemoteSelect
              placeholder='Busque pelo ID ou nome do produto'
              dataSearcher={searchProducts}
              onChange={this.props.onProductChange}
            />
          </Col>
          <Col span={24}>
            <p style={{ marginTop: '15px' }}>{feedbackMessage}</p>
            <Button
              disabled={links === null || links.length === 0}
              onClick={this.props.onSubmit}
            >
              <Icon type='download' /> Gerar
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
