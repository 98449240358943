import * as React from 'react'
import { message } from 'antd'
import { DeliverableList } from './DeliverableList'
import { deliverableService } from '/services/deliverable'
import { productService } from '/services/product'

export class DeliverableListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deliverables: [],
      fetching: false,
    }
  }

  searchProducts = async productId => {
    const data = await productService.search(productId)
    return data.products.map(p => ({
      text: p.name,
      value: p.id,
    }))
  }

  getDeliverableAccesses = async deliverable => {
    const accesses = await deliverableService.getAccesses(deliverable.link)

    return accesses
  }

  loadDeliverables = async ({
    page = 1,
    pageSize = 20,
    query,
    filterObj,
  } = {}) => {
    const { user } = this.props

    // Monitors are not allowed to list all deliverables
    if (user.role == 'monitor' && !query) {
      return {}
    }

    let deliverables = []
    let total = 0

    const filter = filterObj['filter']
    const productId = filterObj['productId']
    // If handling an empty query, list all deliverables
    if (user.role == 'superadmin' && !query) {
      const deliverablesData = await deliverableService.listByPage(
        page,
        pageSize,
        filter,
        productId,
      )

      deliverables = deliverablesData.deliverables
      total = deliverablesData.total
      return { page, pageSize, data: deliverables, totalCount: total }
    }

    const deliverablesData = await deliverableService.search(query, {
      page,
      pageSize,
      filter,
      productId,
    })

    deliverables = deliverablesData.deliverables

    total = deliverablesData.total

    return { page, pageSize, data: deliverables, totalCount: total }
  }

  onDelete = async deliverable => {
    const status = await deliverableService.delete(deliverable)
    if (status === 200) {
      return {
        ok: true,
        msg: `Entregável desabilitado com sucesso.`,
      }
    } else {
      return {
        ok: false,
        msg: 'Ocorreu algum erro. Tente novamente em alguns segundos',
      }
    }
  }

  onEdit = async ({ id, productId, delivered, autoDelivery }) => {
    await deliverableService.editById(id, {
      productId,
      delivered,
      autoDelivery,
    })

    return {
      ok: true,
      msg: `Entregável editado com sucesso!`,
      item: { id, productId, delivered, autoDelivery },
    }
  }

  render() {
    return (
      <DeliverableList
        loadDeliverables={this.loadDeliverables}
        getDeliverableAccesses={this.getDeliverableAccesses}
        searchProducts={this.searchProducts}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        match={this.props.match}
        user={this.props.user}
      />
    )
  }
}
