import axios from 'axios'

const client = axios.create({
  baseURL: 'https://api.mercadolibre.com',
  // Don't throw on 4/5xx errors
  validateStatus: () => true,
})

export const mercadolivreService = {
  async getUserInfo(userId) {
    const { data } = await client.get(`/users/${userId}`)

    return data
  },
}
