import {client} from '/utils/http-client'
import {sessionStore} from '/stores/SessionStore'

export const templateService = {
  async listTemplates() {
    const {data} = await client.get('/delivery-templates', {
      headers: {'x-access-token': sessionStore.getToken()},
    })

    return data
  },
  async createTemplate({name, content, subject, isDefault}) {
    const {data} = await client.post(
      '/delivery-templates',
      {name, content, subject, isDefault},
      {
        headers: {'x-access-token': sessionStore.getToken()},
      },
    )

    return data.id
  },

  async deleteById(id) {
    return await client.delete(`/delivery-templates/${id}`, {
      headers: {'x-access-token': sessionStore.getToken()},
    })
  },

  async editTemplate(template) {
    const templateCopy = {...template}
    const id = templateCopy.id
    delete templateCopy.id

    await client.patch(`/delivery-templates/${id}`, templateCopy, {
      headers: {'x-access-token': sessionStore.getToken()},
    })
  },
}
