import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'

export const eventService = {
  async listByPage(page, pageSize) {
    const { data } = await client.get(
      `/events?page=${page}&pageSize=${pageSize}`,
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return {
      total: data.total,
      events: data.events,
    }
  },

  async search(eventId) {
    const { data } = await client.get(`/events/${eventId}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async createEvent(event) {
    const params = { ...event, enabled: true }

    const { data } = await client.post('/events', params, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },

  async editEvent({ id, name, date, winnersQuantity, enabled }) {
    const params = { name, date, winnersQuantity, enabled }

    const { status } = await client.patch(`/events/${id}`, params, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return status === 200
  },

  async deleteEvent(event) {
    const status = await client.delete(`/events/${event.id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return status
  },

  async listLastWinners(eventId) {
    const { data } = await client.get(`/events/${eventId}/winners`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },
}
