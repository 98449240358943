import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'

export const storeDeliveryRuleService = {
  async listByPage(page, pageSize) {
    const {
      data,
    } = await client.get(
      `/store-delivery-rules?page=${page}&pageSize=${pageSize}`,
      { headers: { 'x-access-token': sessionStore.getToken() } },
    )
    return {
      total: data.total,
      rules: data.rules,
    }
  },

  async createRule(rule) {
    const { data } = await client.post('/store-delivery-rules', rule, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data.ruleId
  },

  async editRule(rule) {
    const ruleCopy = { ...rule }
    const id = ruleCopy.id
    delete ruleCopy.id

    const { status } = await client.patch(`/store-delivery-rules/${id}`, rule, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return status
  },

  async deleteRuleById(id) {
    await client.delete(`/store-delivery-rules/${id}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })
  },
}
