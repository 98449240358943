import * as React from 'react'
import { message, Card } from 'antd'
import { DataTable } from '/components/DataTable'
import { CategoryForm } from '/components/CategoryForm'

function makeInitialValues(category) {
  const initialValues = {
    id: category ? category.id : '',
    name: category ? category.name : '',
    tutorialUrl: category ? category.tutorialUrl : '',
    tutorialDescription: category ? category.tutorialDescription : '',
    automaticRedeemUrl: category ? category.automaticRedeemUrl : '',
  }

  return initialValues
}

export class CategoriesList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { schema, onEdit, onCreate, onDelete, loadCategories } = this.props
    return (
      <Card title='Categorias' bordered={false}>
        <DataTable
          dataLoader={loadCategories}
          header={header}
          delete={{ onDelete }}
          edit={{
            type: 'modal',
            text: 'Editar categoria',
            formik: {
              initialValues: makeInitialValues,
              onSubmit: onEdit,
              schema,
            },
            render: (formikProps, category) => {
              return (
                <CategoryForm
                  formikProps={formikProps}
                  type='edit'
                  category={category}
                />
              )
            },
          }}
          create={{
            type: 'modal',
            text: 'Criar categoria',
            formik: {
              initialValues: makeInitialValues,
              onSubmit: onCreate,
              schema,
            },
            render: formikProps => {
              return <CategoryForm formikProps={formikProps} type='create' />
            },
          }}
        />
      </Card>
    )
  }
}

const header = {
  id: { title: 'ID' },
  name: {
    title: 'Nome',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  tutorialUrl: { title: 'URL para o Tutorial' },
}
