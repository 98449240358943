import {client} from '/utils/http-client'
import {sessionStore} from '/stores/SessionStore'
import qs from 'query-string'

export const logService = {
  async listByPage(page, pageSize) {
    const {data, status} = await client.get(
      `/logs?page=${page}&pageSize=${pageSize}`,
      {
        headers: {'x-access-token': sessionStore.getToken()},
      },
    )

    return {
      total: data.total,
      logs: data.logs,
    }
  },

  async search(query, {page, pageSize} = {}) {
    const params = qs.stringify({
      query,
      page,
      pageSize,
    })

    const {data} = await client.get(`/logs/search?${params}`, {
      headers: {'x-access-token': sessionStore.getToken()},
    })

    return data
  }
}
