import * as React from 'react'
import {LoginForm} from '/components/Login'
import {client} from '/utils/http-client'
import {GenericError} from '/utils/errors'

export class LoginFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'initial', // One of 'initial', 'error', 'loading', 'success'
      errorMsg: null, // If status is 'error', this will be a string
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    const email = e.target.elements['login-email'].value
    const password = e.target.elements['login-password'].value

    this.setState({status: 'loading'})
    const {data, status} = await client.post('/sessions', {
      email,
      password,
    })

    if (status === 400) {
      if (data.code === 'InvalidEmail') {
        this.setState({
          status: 'error',
          errorMsg: 'Este email não está cadastrado.',
        })
      } else if (data.code === 'InvalidPassword') {
        this.setState({
          status: 'error',
          errorMsg: 'A senha é inválida.',
        })
      } else {
        this.setState({
          status: 'initial',
        })
        throw new GenericError(`Unknown code ${data.code}`)
      }
    } else {
      const userData = {
        email,
        token: data.accessToken,
      }
      this.setState({status: 'success'})
      this.props.loginSuccess(userData)
    }
  }

  render() {
    return (
      <LoginForm
        status={this.state.status}
        errorMsg={this.state.errorMsg}
        handleSubmit={this.handleSubmit}
        form={this.props.form}
      />
    )
  }
}
