import * as React from 'react'
import {Form, Input, Select, Icon, Button, Row, Col} from 'antd'
import {Link} from 'react-router-dom'

const {Item} = Form
const {Option} = Select

export class UserForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Form
        style={{maxWidth: '400px'}}
        onChange={this.props.onDataChange}
        onSubmit={this.props.onSubmit}
      >
        <Item>
          <Input
            name='name'
            prefix={<Icon type='user' style={{color: 'rgba(0,0,0,.25)'}} />}
            value={this.props.data ? this.props.data.name : ''}
          />
        </Item>
        <Item>
          <Input
            disabled={this.props.create}
            name='email'
            prefix={<Icon type='mail' style={{color: 'rgba(0,0,0,.25)'}} />}
            value={this.props.data ? this.props.data.email : ''}
          />
        </Item>
        <Item>
          <Input.Password
            name='password'
            prefix={<Icon type='lock' style={{color: 'rgba(0,0,0,.25)'}} />}
            value={this.props.data ? this.props.data.password : ''}
          />
        </Item>
        <Item>
          <Select
            placeholder='Permissões'
            value={this.props.data ? this.props.data.role : 'monitor'}
            onChange={this.props.onDataChange}
          >
            <Option value='monitor'>Monitor</Option>
            <Option value='admin'>Admin</Option>
            <Option value='superadmin'>Super admin</Option>
          </Select>
        </Item>
        <Item style={{textAlign: 'center'}}>
          <Row>
            <Col span={12} style={{textAlign: 'left'}}>
              <Link to='/users'>
                <Button style={{width: '95%'}} htmlType='button'>
                  <Icon type='left' />
                  Voltar
                </Button>
              </Link>
            </Col>
            <Col span={12} style={{textAlign: 'right'}}>
              <Button style={{width: '95%'}} type='primary' htmlType='submit'>
                Salvar
              </Button>
            </Col>
          </Row>
        </Item>
      </Form>
    )
  }
}
