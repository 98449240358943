import * as React from 'react'
import { DeliveriesList } from './DeliveriesList'
import { deliveryService } from '/services/delivery'

export class DeliveriesListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deliveries: [],
      fetching: false,
    }
  }

  editDeliveryBundle = async ({ deliveryBundle }) => {
    const { pin, smsAuthEnabled, isBlocked, isGift } = deliveryBundle

    const success = await deliveryService.editDeliveryBundle(pin, {
      smsAuthEnabled,
      isBlocked,
      isGift,
    })

    return {
      ok: success,
      msg: success
        ? `Informações alteradas com sucesso`
        : 'Ocorreu um erro interno. Por favor, entre em contato com o suporte ou tente mais tarde.',
    }
  }

  loadDeliveryItems = async delivery => {
    let data = await deliveryService.listDeliveryItems(delivery)

    return data
  }

  loadDeliveries = async ({ page = 1, pageSize = 20, query } = {}) => {
    // If handling an empty query, list all deliverables
    if (!query) {
      const { deliveries, total } = await deliveryService.listByPage(
        page,
        pageSize,
      )

      return { page, pageSize, data: deliveries, totalCount: total }
    }

    const { deliveries, total } = await deliveryService.search(query, {
      page,
      pageSize,
    })

    return { page, pageSize, data: deliveries, totalCount: total }
  }

  render() {
    return (
      <DeliveriesList
        loadDeliveries={this.loadDeliveries}
        loadDeliveryItems={this.loadDeliveryItems}
        editDeliveryBundle={this.editDeliveryBundle}
        match={this.props.match}
        user={this.props.user}
      />
    )
  }
}
