import * as React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Icon, Button, Card } from 'antd'
import { FormattedDate } from '/components/Misc'
import { DataTable } from '/components/DataTable'
import { BlacklistForm } from './BlacklistForm'

const { Text } = Typography

function makeInitialValues(blacklistEntry) {
  return {
    content: '',
    createdAt: '',
    reason: '',
    link: '',
    type: '',
  }
}
export function BlackList({
  match,
  schema,
  onCreate,
  onDelete,
  listBlacklist,
}) {
  const header = {
    identifier: { title: 'Identificador' },
    type: {
      title: 'Tipo',
      render: type => (
        <Text>{type == 'client_id' ? 'Navegador' : 'Celular'}</Text>
      ),
    },
    link: { title: 'Link' },
    reason: { title: 'Motivo' },
    createdAt: {
      title: 'Data de inserção',
      render: createdAt => <FormattedDate date={new Date(createdAt)} />,
    },
  }

  return (
    <Card title='Blacklist' bordered={false}>
      <Link to={`${match.path}`}>
        <Button>
          <Icon type='left' />
          Voltar
        </Button>
      </Link>
      <DataTable
        searchText={'Busque pelo celular ou identificador do navegador'}
        dataLoader={listBlacklist}
        header={header}
        delete={{ onDelete: entry => onDelete(entry.id) }}
        create={{
          type: 'modal',
          text: 'Inserir novo item',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: onCreate,
            schema,
          },
          render: formikProps => {
            return <BlacklistForm formikProps={formikProps} formType='create' />
          },
        }}
      />
    </Card>
  )
}
