import * as React from 'react'
import { Modal, Form, Select, Button, Popconfirm } from 'antd'
import { useState } from 'react'

const { Item } = Form
const { Option } = Select

export function UpdateOrdersBar({
  mlQualification,
  batchUpdate,
  selectedOrders,
  userRole,
}) {
  const [isModalVisible, setIsModalVisible] = useState()
  const [orderStatus, setOrderStatus] = useState('')
  const [deliveryStatus, setDeliveryStatus] = useState('')
  const [paymentStatus, setPaymentStatus] = useState('')

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    const data = {
      orders: selectedOrders,
      statuses: {
        orderStatus,
        deliveryStatus,
        paymentStatus,
      },
    }

    batchUpdate(data)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div
      style={{
        float: 'right',
      }}
    >
      {!!((selectedOrders.length > 0) & (userRole !== 'monitor')) && (
        <Button
          style={{
            marginRight: '2em',
            zIndex: '10',
          }}
          type='primary'
          onClick={showModal}
        >
          Atualizar Pedidos
        </Button>
      )}
      {userRole == 'superadmin' && (
        <Popconfirm
          onConfirm={() => mlQualification()}
          title='Tem certeza que deseja realizar a qualificação dos pedidos?'
          okText={'Sim, tenho certeza'}
          cancelText={'Não'}
        >
          <Button
            style={{
              zIndex: '10',
            }}
            type='primary'
          >
            Realizar Qualificação
          </Button>
        </Popconfirm>
      )}
      <Modal
        title='Atualizar pedidos'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form>
          <Item label='Pedido'>
            <Select
              value={orderStatus}
              onChange={value => setOrderStatus(value)}
            >
              <Option value=''>Não alterar</Option>
              <Option value='created'>Criado</Option>
              <Option value='approved'>Aprovado</Option>
              <Option value='cancelled'>Cancelado</Option>
            </Select>
          </Item>

          <Item label='Pagamento'>
            <Select
              value={paymentStatus}
              onChange={value => setPaymentStatus(value)}
            >
              <Option value=''>Não alterar</Option>
              <Option value='pending'>Pendente</Option>
              <Option value='analysis'>Análise</Option>
              <Option value='approved'>Aprovado</Option>
              <Option value='validated_tel'>Validado_TEL</Option>
              <Option value='validated_doc'>Validado_DOC</Option>
              <Option value='cancelled'>Cancelado</Option>
            </Select>
          </Item>
          <Item label='Envio'>
            <Select
              value={deliveryStatus}
              onChange={value => setDeliveryStatus(value)}
            >
              <Option value=''>Não alterar</Option>
              <Option value='pending'>Pendente</Option>
              <Option value='sent'>Enviado</Option>
              <Option value='delivered'>Entregue</Option>
              <Option value='aborted'>Abortado</Option>
            </Select>
          </Item>
        </Form>
      </Modal>
    </div>
  )
}
