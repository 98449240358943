export const localTranslator = {
  analysis: 'Análise', // Order payment currently in analysis
  created: 'Criado', // Order created
  confirmed: 'Confirmado', //Initial status of an order, not yet paid.
  payment_required: 'Pagamento requerido', //Order payment must have been confirmed to display user information.
  payment_in_process: 'Pagamento em processo', //There is an order-related payment, but not yet credited.
  partially_paid: 'Pacialmete pago', //The order has an associated but insufficient credit credited payment.
  paid: 'Pago', //The order has an associated payment credited.
  cancelled: 'Cancelado', //For some reason, the order was not completed.
  invalid: 'Invalido', //The order was invalidated by coming from a malicious buyer.
  to_be_agreed: 'A Combinar', //The shipping to be agree with seller.
  pending: 'Pendente', //The seller not send yet.
  sent: 'Enviado', //The delivery was sent
  aborted: 'Abortado', //The delivery was aborted due validation failures
  shipped: 'Enviado', //The seller sent the item.
  delivered: 'Entregue', //The item was arrived
  not_delivered: 'Não Entregue', //For some reason the item can't be delivered
  approved: 'Aprovado', //The payment has been approved and accredited.
  refunded: 'Devolvido', //Payment was refunded to the user.
  pending: 'Pendente', //The user has not yet completed the payment process.
  authorized: 'Autorizado', //The payment has been authorized but not captured yet.
  in_process: 'Em processo', //Payment is being reviewed.
  in_mediation: 'Em mediação', //Users have initiated a dispute.
  rejected: 'Rejeitado', //Payment was rejected. The user may retry payment.
  cancelled: 'Cancelado', //Payment was cancelled by one of the parties or because time for payment has expired
  charged_back: 'Charged Back', //Was made a chargeback in the buyer’s credit card.
  not_enough_stock: 'Sem estoque', //Delivery aborted due not enough stock
  product_not_found: 'Produto não encontrado', //Delivery aborted as the product was not found
  max_value: 'Valor máximo atingido', //Delivery aborted by max_value rule
  max_items: 'Número máximo de itens atingido', //Delivery aborted by max_items rule
  freight: 'Frete pago', //Delivery aborted by freight rule
  payment_type: 'Pagamento com cartão de crédito', //Delivery aborted by payment_type rule
  points_ticket: 'Pontuação mínima (Boleto)', //Delivery aborted by points_ticket rule
  points_credit: 'Pontuação mínima (CC)', //Delivery aborted by points_credit rule
  points_balance: 'Pontuação mínima (Saldo MP)', //Delivery aborted by points_balance rule
  autodelivery_disabled: 'Entrega automática desabilitada', //Delivery aborted by autodelivery_disabled rule
  product_autodelivery_disabled: 'Produto com entrega automática desabilitada', //Delivery aborted by autodelivery_disabled rule
  last_orders: 'Número de compras excedido no período', //Delivery aborted because it exceeded the maximum number of redeems in a period
  credit: 'Cartão de crédito', //Payment type
  ticket: 'Boleto', //Payment type
  transfer: 'Transferência', //Payment type
  balance: 'Saldo ML', //Payment type
  validated_tel: 'Validado_TEL',
  validated_doc: 'Validado_DOC',
  in_queue: 'Na fila', //Delivery in queue to be sent (non prime/vip orders)
}
