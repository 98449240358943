import * as React from 'react'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { categoryService } from '/services'

const Option = Select.Option

export class RemoteSelect extends React.Component {
  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.searchItems = debounce(this.searchItems, 800)
  }

  state = {
    data: this.props.dataValues || [],
    value: this.props.initialValue || undefined,
    fetching: false,
  }

  searchItems = async value => {
    this.setState({ data: [], fetching: true })
    const data = await this.props.dataSearcher(value)
    this.setState({ data, fetching: false })
  }

  handleChange = value => {
    this.setState({
      value,
      fetching: false,
    })

    this.props.onChange(value)
  }

  async componentDidMount() {
    const { initialValue, dataSearcher } = this.props

    if (initialValue) {
      const data = await this.props.dataSearcher(initialValue)
      const valueIdx = data.findIndex(
        d => d.value == initialValue || d.id == initialValue,
      )

      if (valueIdx >= 0) {
        this.setState({ data, value: data[valueIdx].value })

        this.props.onChange(data[valueIdx].value)
      }
    }
  }

  render() {
    const { fetching, data, value } = this.state
    const { placeholder, style } = this.props

    const selectProps = {
      value,
      defaultValue: value,
      placeholder,
      filterOption: false,
      style: { width: '100%', ...style },
      labelInValue: this.props.labelInValue || false,
      onChange: this.handleChange,
      showSearch: true,
      notFoundContent: fetching ? <Spin size='small' /> : null,
      onSearch: this.searchItems,
    }

    return (
      <Select {...selectProps}>
        {data.map(v => (
          <Option value={v.value} key={v.value}>
            {v.text}
          </Option>
        ))}
      </Select>
    )
  }
}
