import React, {useState} from 'react'
import {
  Card,
  Checkbox,
  Modal,
  Form,
  Input,
  Select,
  Icon,
  Button,
  Row,
  Popover,
  Col,
  Alert
} from 'antd'
import {Link} from 'react-router-dom'
import {Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {RemoteSelect} from '/components/Form'
import 'emoji-mart/css/emoji-mart.css'
import {Picker, Emoji} from 'emoji-mart'
import {splitAt} from 'ramda'

const {Item} = Form
const {Option} = Select

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

export function TemplateForm({formikProps}) {
  const {
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValidating,
    setFieldValue,
  } = formikProps

  const [showWarning, setShowWarning] = useState(false)
  const [cursorIndex, setCursorIndex] = useState(0)

  const insertText = (text, content) => {
    const [left, right] = splitAt(cursorIndex, content)
    const newTextarea = left + text + right
    setFieldValue('content', newTextarea)
  }

  const onClickCheckbox = event => {
    // Toggle warning
    setShowWarning(!showWarning)

    // Call formik's callback
    handleChange(event)
  }

  return (
    <Form style={{maxWidth: '800px'}} onSubmit={handleSubmit}>
      <Item {...fromFormikErrors('name', errors, touched)}>
        <Input
          name='name'
          placeholder='Nome'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
        />
      </Item>
      <Item {...fromFormikErrors('content', errors, touched)}>
        <Input.TextArea
          rows={4}
          name='content'
          placeholder='Conteúdo'
          onBlur={handleBlur}
          onMouseUp={ev => {
            setCursorIndex(ev.target.selectionStart)
          }}
          onChange={ev => {
            setFieldValue('content', ev.target.value)
            setCursorIndex(ev.target.selectionStart)
          }}
          value={values.content}
        />
        <Popover
          content={
            <Picker
              set='emojione'
              onSelect={emoji => {
                insertText(emoji.native, values.content)
              }}
            />
          }
          title='Inserir emoji'
          trigger='click'
        >
          <Button>Inserir emoji</Button>
        </Popover>
        <Button
          style={{marginLeft: '5px'}}
          onClick={() => insertText('[PRODUTO]', values.content)}
        >
          Inserir nome do produto
        </Button>
        <Button
          style={{marginLeft: '5px'}}
          onClick={() => insertText('[PIN]', values.content)}
        >
          Inserir código PIN
        </Button>
      </Item>
      <Item {...fromFormikErrors('subject', errors, touched)}>
        <Input
          name='subject'
          placeholder='Assunto'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.subject}
        />
        <Popover
          content={
            <Picker
              set='emojione'
              onSelect={emoji => {
                setFieldValue('subject', values.subject + emoji.native)
              }}
            />
          }
          title='Inserir emoji'
          trigger='click'
        >
          <Button>Inserir emoji</Button>
        </Popover>
        <Button
          style={{marginLeft: '5px'}}
          onClick={() => {
            setFieldValue('subject', values.subject + '[PRODUTO]')
          }}
        >
          Inserir nome do produto
        </Button>
        <Button
          style={{marginLeft: '5px'}}
          onClick={() => {
            setFieldValue('subject', values.subject + '[QTD]')
          }}
        >
          Inserir quantidade
        </Button>
      </Item>
      <Item {...fromFormikErrors('isDefault', errors, touched)}>
        <Checkbox
          name='isDefault'
          onBlur={handleBlur}
          onClick={onClickCheckbox}
          checked={values.isDefault}
        >
          Padrão
        </Checkbox>
        {showWarning && (
          <React.Fragment>
            <br />
            <Alert
              message='Isso desmarcará qualquer outro template padrão prévio.'
              type='warning'
              style={{marginTop: '15px'}}
              showIcon
            />
          </React.Fragment>
        )}
      </Item>
    </Form>
  )
}
