import * as React from 'react'
import { EventsList } from './EventsList'
import { eventService } from '/services/event'
import * as Yup from 'yup'

const EventSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Digite um nome.'),
  date: Yup.string().required('Digite uma data.'),
  winnersQuantity: Yup.string().required('Digite a quantidade de vencedores.'),
})

export class EventsListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
    }
  }

  onCreate = async values => {
    const { event } = await eventService.createEvent(values)

    if (event) {
      const item = { ...values, id: event.id }
      return { ok: true, msg: 'Evento criado com sucesso!', item }
    } else {
      return { ok: false, msg: 'Ocorreu um erro ao criar o evento.' }
    }
  }

  onEdit = async values => {
    const updated = await eventService.editEvent(values)

    return {
      ok: updated,
      msg: updated
        ? 'Evento editado com sucesso!'
        : 'Ocorreu um erro ao editar o evento.',
      item: values,
    }
  }

  onDelete = async event => {
    const { status } = await eventService.deleteEvent(event)

    if (status === 204) {
      return {
        ok: true,
        msg: `Evento desabilitado com sucesso.`,
      }
    } else {
      return {
        ok: false,
        msg: 'Ocorreu algum erro. Tente novamente em alguns segundos',
      }
    }
  }

  loadEvents = async ({ page = 1, pageSize = 20, query } = {}) => {
    if (!query) {
      const { events, total } = await eventService.listByPage(page, pageSize)
      return { page, pageSize, data: events, totalCount: total }
    }

    const { event } = await eventService.search(query)

    if (event) {
      return { page, pageSize, data: [event], totalCount: 1 }
    }
    return { page, pageSize, data: [], totalCount: 0 }
  }

  loadLastWinners = async eventId => {
    const { eventWinners } = await eventService.listLastWinners(eventId)
    if (eventWinners) {
      return { data: eventWinners }
    }
    return { data: {} }
  }

  render() {
    return (
      <EventsList
        loadEvents={this.loadEvents}
        loadLastWinners={this.loadLastWinners}
        onCreate={this.onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        schema={EventSchema}
      />
    )
  }
}
