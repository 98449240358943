import * as React from 'react'
import { Checkmark } from '/components/Misc'
import { DataTable } from '/components/DataTable'
import { StoreDeliveryRuleForm } from '/components/StoreDeliveryRuleForm'

function makeInitialValues(rule) {
  let initialValues = {
    id: rule ? rule.id : '',
    active: rule ? rule.active : false,
    maxValue: rule ? rule.maxValue : 0,
    maxItems: rule ? rule.maxItems : 0,
    maxOrdersPerPeriod: rule ? rule.maxOrdersPerPeriod : 0,
  }
  return initialValues
}

export function StoreRulesDataTable({
  loadRules,
  onCreate,
  onEdit,
  onDelete,
  schema,
}) {
  return (
    <DataTable
      searchText={'Busque pelo titulo da regra.'}
      dataLoader={loadRules}
      header={header}
      modalSize={'768px'}
      edit={{
        type: 'modal',
        text: 'Editar regra',
        formik: {
          initialValues: makeInitialValues,
          onSubmit: onEdit,
          schema,
        },
        render: (formikProps, rule) => {
          return (
            <StoreDeliveryRuleForm
              formikProps={formikProps}
              formType='edit'
              rule={rule}
            />
          )
        },
      }}
      create={{
        type: 'modal',
        text: 'Criar regra',
        formik: {
          initialValues: makeInitialValues,
          onSubmit: onCreate,
          schema,
        },
        render: formikProps => {
          return (
            <StoreDeliveryRuleForm
              formikProps={formikProps}
              formType='create'
            />
          )
        },
      }}
      delete={{
        text: 'Tem certeza que deseja deletar',
        onDelete: rule => onDelete(rule.id),
      }}
    />
  )
}

const header = {
  id: {
    title: 'Id',
  },
  active: {
    title: 'Ativa',
    render: active => <Checkmark checked={active} />,
  },
  maxValue: {
    title: 'Valor máximo',
  },
  maxItems: {
    title: 'Qtd máx de itens',
  },
  maxOrdersPerPeriod: {
    title: 'Qtd máxima de pedidos em um período',
  },
}
