import * as React from 'react'
import { Route } from 'react-router-dom'
import { DeliveryRuleListContainer } from '/components/DeliveryRuleList'
import { DeliveryRuleEditContainer } from '/components/DeliveryRuleEdit'
import { DeliveryRuleCreateContainer } from '/components/DeliveryRuleCreate'

export class DeliveryRulePage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { match, user } = this.props
    return (
      <div>
        <Route
          exact
          path={`${match.path}`}
          render={props => <DeliveryRuleListContainer {...props} user={user} />}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={props => <DeliveryRuleEditContainer {...props} user={user} />}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={props => (
            <DeliveryRuleCreateContainer {...props} user={user} />
          )}
        />
      </div>
    )
  }
}
