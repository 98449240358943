import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'

export const hypeService = {
  async startBulkRedeem({ productId, amount, customer, orderId }) {
    const response = await client.post(
      `/hype/bulk-redeem`,
      { productId, amount, customer, orderId },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return response
  },
}
