import * as React from 'react'
import { Form, Input, Button } from 'antd'

export function MercadoLivreAuth() {
  return (
    <Form
      action='https://auth.mercadolivre.com.br/authorization'
      onSubmit={() => true}
    >
      <Form.Item>
        <Input type='hidden' name='response_type' value='code' />
        <Input
          type='hidden'
          name='client_id'
          value={process.env.ML_CLIENT_ID}
        />
      </Form.Item>
      <Button htmlType='submit'>Autenticar com Mercado Livre</Button>
    </Form>
  )
}
