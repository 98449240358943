import * as React from 'react'
import {Menu, Icon} from 'antd'
import {UserAvatarContainer} from '/components/UserAvatar'
import {RightContent} from './RightContent'

export class Topbar extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Menu mode='horizontal' theme='dark' style={{lineHeight: '60px'}}>
        <Menu.Item>
          <img
            src='https://gcmgames-static.s3.amazonaws.com/img/logo.png'
            style={{height: '46px', marginLeft: '-10px'}}
            value='GCM Manager'
          />
        </Menu.Item>
        <RightContent>
          <UserAvatarContainer {...this.props} user={this.props.user} />
        </RightContent>
      </Menu>
    )
  }
}
