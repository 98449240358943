import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'
import qs from 'query-string'

export const storeOrderService = {
  async listStoreOrders({ page, pageSize, query, filters }) {
    const params = qs.stringify({
      page,
      pageSize,
      query,
      ...filters,
    })
    const { data } = await client.get(`/store-orders?${params}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return {
      total: data.total,
      orders: data.orders,
    }
  },

  async listOrderItems(order) {
    const { data } = await client.get(`/store-orders/${order.id}/items`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return data
  },
}
