import * as React from 'react'
import { message } from 'antd'
import { orderService, storeOrderService } from '/services'
import { StoreOrderList } from './StoreOrderList'
export class StoreOrderListContainer extends React.Component {
  loadOrders = async ({ page = 1, pageSize = 20, query, filterObj } = {}) => {
    const data = await storeOrderService.listStoreOrders({
      page,
      pageSize,
      query,
      filters: filterObj,
    })
    const { orders, total } = data
    return { page, pageSize, data: orders, totalCount: total }
  }

  editOrder = async order => {
    const { status, paymentStatus, deliveryStatus } = order
    const success = await orderService.edit(order.id, {
      status,
      paymentStatus,
      deliveryStatus,
    })
    return {
      ok: success,
      msg: success
        ? `Pedido alterado com sucesso!`
        : 'Não foi possível alterar o pedido',
      item: order,
    }
  }

  deliveryOrder = async orderId => {
    const deliveryId = await orderService.deliveryOrder(orderId)
    if (deliveryId) {
      message.success('Entrega realizada com sucesso!')
    } else {
      message.error(
        'Não foi possível realizar a entrega, estoque insuficiente ou entrega já realizada',
      )
    }
  }

  requestDataAnalysis = async orderId => {
    const success = await orderService.requestDataAnalysis(orderId)
    if (success) {
      message.success('Dados solicitados ao cliente')
    } else {
      message.error('Não foi possível realizar esta operação')
    }
  }

  editCustomerNotesByOrder = async order => {
    const { id, customerNotes } = order
    const response = await orderService.editCustomerNotesByOrder(
      id,
      customerNotes,
    )

    const { status, data } = response
    const success = status === 200
    let message = ''

    if (success) {
      message = 'Pedido alterado com sucesso!'
    } else if (data.code == 'NoOrderFound') {
      message = 'Não foi possível encontrar o pedido relacionado'
    } else if (data.code == 'NoCustomerDocumentFound') {
      message = 'Não foi possível encontrar o CPF do cliente'
    } else if (data.code == 'NoLogsEdited') {
      message = 'Não foi possível atualizar os dados'
    } else {
      message = 'Não foi possível alterar o pedido'
    }

    return {
      ok: success,
      msg: message,
      item: order,
    }
  }

  loadOrderItems = async order => {
    let data = await storeOrderService.listOrderItems(order)

    return data
  }

  render() {
    return (
      <div>
        <StoreOrderList
          {...this.props}
          deliveryOrder={this.deliveryOrder}
          requestDataAnalysis={this.requestDataAnalysis}
          loadOrders={this.loadOrders}
          editCustomerNotesByOrder={this.editCustomerNotesByOrder}
          loadOrderItems={this.loadOrderItems}
        />
      </div>
    )
  }
}
