import * as React from 'react'
import {Route} from 'react-router-dom'
import {UsersListContainer} from '/components/UsersList'
import {UserEditContainer} from '/components/UserEdit'
import {UserCreateContainer} from '/components/UserCreate'

export class UsersPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {match, user} = this.props
    return (
      <div>
        <Route
          exact
          path={`${match.path}`}
          render={props => <UsersListContainer {...props} user={user} />}
        />
        <Route
          exact
          path={`${match.path}/edit/:id`}
          render={props => <UserEditContainer {...props} user={user} />}
        />
        <Route
          exact
          path={`${match.path}/create`}
          render={props => <UserCreateContainer {...props} user={user} />}
        />
      </div>
    )
  }
}
