import * as React from 'react'
import {Route} from 'react-router-dom'
import {TemplateList} from '/components/TemplateList'

export class TemplatesPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {match} = this.props
    return (
      <div>
        <Route
          exact
          path={`${match.path}`}
          render={props => <TemplateList {...this.props} />}
        />
      </div>
    )
  }
}
