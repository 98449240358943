import * as React from 'react'
import { Fragment } from 'react'
import {
  Card,
  Typography,
  Icon,
  Tooltip,
  Tag,
  Popconfirm,
  Popover,
  Button,
  Checkbox,
} from 'antd'

import { without, includes, append } from 'ramda'

const { Text } = Typography

import { DataTable, FilterTypes } from '/components/DataTable'
import { FormattedDate } from '/components/Misc'
import { UpdateOrdersBar } from '/components/UpdateOrdersBar'
import { EditOrderForm } from '/components/OrderForm'
import * as Yup from 'yup'
import { useState } from 'react'

import { localTranslator } from '/utils/translator'

function makeInitialValues(order) {
  let initialValues = {
    ...order,
    customerNotes: { timestamp: null },
  }
  return initialValues
}

const EditOrderSchema = Yup.object().shape({
  paymentStatus: Yup.string(),
  deliveryStatus: Yup.string(),
  situation: Yup.string(),
})

const paymentTypeTooltip = () => {
  const content = (
    <Fragment>
      <Icon type='credit-card' /> Cartão de crédito <br />
      <Icon type='barcode' /> Boleto <br />
      <Icon type='dollar' /> Saldo ML <br />
      <Icon type='bank' /> Transferência <br />
    </Fragment>
  )
  return (
    <Fragment>
      Tipo de pagamento{' '}
      <Popover placement='topRight' content={content}>
        <Icon type='question-circle-o' />
      </Popover>
    </Fragment>
  )
}

export function OrderList({
  loadOrders,
  searchOrders,
  deliveryOrder,
  requestDataAnalysis,
  editOrder,
  mlQualification,
  userRole,
  batchUpdate,
}) {
  let selectedOrders = []

  const [orders, setOrders] = useState([])

  const isChecked = id => (includes(id, selectedOrders) ? true : false)

  const handleCheckBox = id => {
    if (includes(id, selectedOrders)) {
      selectedOrders = without([id], selectedOrders)
    } else {
      selectedOrders = append(id, selectedOrders)
    }

    setOrders(selectedOrders)
  }

  return (
    <Card title='Gerenciar Pedidos ML' bordered={false}>
      <UpdateOrdersBar
        mlQualification={mlQualification}
        batchUpdate={batchUpdate}
        selectedOrders={orders}
        userRole={userRole}
      />

      <DataTable
        searchText={'Busque pelo ID do pedido ou título do anúncio'}
        dataLoader={loadOrders}
        header={header(handleCheckBox, userRole, isChecked)}
        modalSize='1280px'
        edit={{
          type: 'modal',
          text: 'Editar pedido',
          formik: {
            initialValues: makeInitialValues,
            onSubmit: editOrder,
            schema: EditOrderSchema,
          },
          render: (formikProps, order) => {
            return <EditOrderForm formikProps={formikProps} order={order} />
          },
        }}
        extraActions={{
          render: order => {
            return (
              <Fragment>
                <Popconfirm
                  onConfirm={() => requestDataAnalysis(order.id)}
                  title='Tem certeza que deseja solicitar análise para este pedido?'
                  okText={'Sim, tenho certeza'}
                  cancelText={'Não'}
                >
                  <Tooltip title='Solicitar análise de dados'>
                    <Icon
                      type='security-scan'
                      style={{
                        fontSize: 18,
                        color: 'rgba(0, 0, 0, 0.45)',
                        marginRight: 5,
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  onConfirm={() => deliveryOrder(order.id)}
                  title='Tem certeza que deseja enviar este pedido?'
                  okText={'Sim, tenho certeza'}
                  cancelText={'Não'}
                >
                  <Tooltip title='Enviar pedido'>
                    <Icon
                      type='check'
                      style={{
                        fontSize: 18,
                        color: 'rgba(0, 0, 0, 0.45)',
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
              </Fragment>
            )
          },
        }}
        dataLoaderFilters={{
          startDate: {
            text: 'Data inicial',
            type: FilterTypes.Date,
          },
          endDate: {
            text: 'Data final',
            type: FilterTypes.Date,
          },
          sorted: {
            text: 'Ordenar por',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Mais recentes', value: 'date_desc' },
              { text: 'Menos recentes', value: 'date_asc' },
            ],
          },
          status: {
            type: FilterTypes.Select,
            text: 'Status do Pedido',
            selectOptions: [
              { text: 'Criado', value: 'created' },
              { text: 'Aprovado', value: 'approved' },
              { text: 'Cancelado', value: 'cancelled' },
            ],
          },
          paymentStatus: {
            text: 'Pagamento',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Pendente', value: 'pending' },
              { text: 'Aprovado', value: 'approved' },
              { text: 'Análise', value: 'analysis' },
              { text: 'Validado_TEL', value: 'validated_tel' },
              { text: 'Validado_DOC', value: 'validated_doc' },
              { text: 'Cancelado', value: 'cancelled' },
            ],
          },
          deliveryStatus: {
            text: 'Envio',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Pendente', value: 'pending' },
              { text: 'Enviado', value: 'sent' },
              { text: 'Entregue', value: 'delivered' },
              { text: 'Abortado', value: 'aborted' },
            ],
          },
          abortReason: {
            text: 'Razão da abortagem',
            type: FilterTypes.Select,
            selectOptions: [
              { text: 'Frete pago', value: 'freight' },
              { text: 'Valor máximo', value: 'max_value' },
              { text: 'Número máximo de itens', value: 'max_items' },
              { text: 'Sem estoque', value: 'not_enough_stock' },
              {
                text: 'Entrega automática desabilitada',
                value: 'autodelivery_disabled',
              },
              {
                text: 'Produto com entrega automática desabilitada',
                value: 'product_autodelivery_disabled',
              },
              {
                text: 'Número máximo de compras no período',
                value: 'last_orders',
              },
            ],
          },
        }}
      />
    </Card>
  )
}

const header = (handleCheckBox, userRole, isChecked) => {
  return {
    id: {
      title: (
        <div
          style={{
            marginLeft: '2em',
          }}
        >
          ID
        </div>
      ),
      render: id => {
        return (
          <Fragment>
            {userRole !== 'monitor' && (
              <Checkbox
                onClick={() => handleCheckBox(id)}
                checked={isChecked(id)}
              />
            )}

            <a
              href={`https://mercadolivre.com.br/vendas/mensagens/${id}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                marginLeft: '0.9em',
                textDecoration: 'underline',
                color: 'rgba(0, 0, 0, 0.65)',
              }}
            >
              {id}
            </a>
          </Fragment>
        )
      },
    },
    status: {
      title: 'Status',
      render: status => {
        const tagColor =
          status == 'approved'
            ? 'green'
            : status == 'cancelled'
            ? 'red'
            : 'gray'
        return <Tag color={tagColor}>{localTranslator[status]}</Tag>
      },
    },
    title: {
      title: 'Titulo',
    },
    amount: {
      title: 'Quantidade',
    },
    unitaryValue: {
      title: 'Valor unitário',
      render: unitaryValue => {
        return unitaryValue && 'R$' + unitaryValue
      },
    },
    paymentType: {
      title: paymentTypeTooltip,
      render: paymentType => {
        let iconType = 'credit-card'
        if (paymentType === 'ticket') {
          iconType = 'bar-code'
        }
        if (paymentType === 'balance') {
          iconType = 'dollar'
        }
        if (paymentType === 'transfer') {
          iconType = 'bank'
        }
        return (
          <Tooltip title={localTranslator[paymentType]}>
            <div style={{ textAlign: 'center' }}>
              <Icon type={iconType} />
            </div>
          </Tooltip>
        )
      },
    },
    paymentStatus: {
      title: 'Pagamento',
      render: paymentStatus => {
        const tagColor = paymentStatus.match(/validated_doc|validated_tel/)
          ? 'blue'
          : paymentStatus == 'approved'
          ? 'green'
          : paymentStatus == 'analysis'
          ? 'orange'
          : paymentStatus == 'cancelled'
          ? 'red'
          : 'gray'
        return <Tag color={tagColor}>{localTranslator[paymentStatus]}</Tag>
      },
    },
    deliveryStatus: {
      title: 'Envio',
      render: (status, item) => {
        const tagColor = status.match(/sent|delivered/)
          ? 'green'
          : status == 'aborted'
          ? 'red'
          : 'blue'
        if (status == 'aborted' && item.abortReason) {
          const [abortReason] = item.abortReason.split(':')

          return (
            <Fragment>
              <Tag color={tagColor}>{`${localTranslator[status]}`}</Tag>
              <Text>{localTranslator[abortReason]}</Text>
            </Fragment>
          )
        } else {
          return <Tag color={tagColor}>{localTranslator[status]}</Tag>
        }
      },
    },
    createdAt: {
      title: 'Data de criação',
      render: date => {
        return <FormattedDate date={date} />
      },
    },
  }
}
