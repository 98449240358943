import * as React from 'react'
import { Modal, Form, Input, Select, Icon, Button } from 'antd'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'

const { Item } = Form
const { Option } = Select

function fromFormikErrors(name, errors, touched) {
  const errorMsg = errors[name]
  const hasError = touched[name] == true && errorMsg !== undefined
  return {
    validateStatus: hasError ? 'error' : 'success',
    help: hasError ? errorMsg : null,
  }
}

/* formType is one of 'create', 'edit' */
export function BlacklistForm({
  onCancel,
  onSubmit,
  product,
  formType,
  formikProps,
}) {
  const {
    values,
    errors,
    status,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValidating,
    setFieldValue,
  } = formikProps

  return (
    <Form style={{ maxWidth: '500px' }} onSubmit={handleSubmit}>
      <Item>
        {formType === 'edit' && (
          <Input
            disabled
            name='id'
            prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.id}
          />
        )}
      </Item>
      <Item {...fromFormikErrors('identifier', errors, touched)}>
        <Input
          name='identifier'
          placeholder='Identificador'
          prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.identifier}
        />
      </Item>
      <Item {...fromFormikErrors('reason', errors, touched)}>
        <Input
          name='reason'
          placeholder='Motivo'
          prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.reason}
        />
      </Item>
      <Item {...fromFormikErrors('link', errors, touched)}>
        <Input
          name='link'
          placeholder='Link acessado'
          prefix={<Icon type='api' style={{ color: 'rgba(0,0,0,.25)' }} />}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.link}
        />
      </Item>
      <Item {...fromFormikErrors('type', errors, touched)}>
        <Select
          defaultValue='client_id'
          value={values.type}
          onBlur={handleBlur}
          onChange={value => setFieldValue('type', value)}
        >
          <Option value='client_id'>Navegador</Option>
          <Option value='cellphone'>Celular</Option>
        </Select>
      </Item>
    </Form>
  )
}
