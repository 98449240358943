import * as React from 'react'
import {LoginFormContainer} from '/components/Login'
import {Centered} from '/components/Misc'

export class LoginPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Centered>
        <LoginFormContainer
          className='login-container'
          loginSuccess={this.props.loginSuccess}
        />
      </Centered>
    )
  }
}
