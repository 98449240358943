import * as React from 'react'

import { Layout, Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
const { Sider } = Layout

export class Sidebar extends React.Component {
  state = {
    collapsed: false,
  }

  onCollapse = collapsed => {
    this.setState({ collapsed })
  }

  render() {
    const { match, user } = this.props

    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <Menu theme='dark' defaultSelectedKeys={[match.url]} mode='inline'>
          <Menu.Item key='/categories' disabled={user.role != 'superadmin'}>
            <Link to='/categories' />
            <Icon type='tags' />
            <span>Categorias</span>
          </Menu.Item>
          <Menu.Item key='/products' disabled={user.role != 'superadmin'}>
            <Link to='/products' />
            <Icon type='shopping' />
            <span>Produtos</span>
          </Menu.Item>
          <Menu.Item key='/store-orders'>
            <Link to='/store-orders' />
            <Icon type='file-done' />
            <span>Pedidos (Loja)</span>
          </Menu.Item>
          <Menu.Item key='/orders'>
            <Link to='/orders' />
            <Icon type='file-done' />
            <span>Pedidos (ML)</span>
          </Menu.Item>
          <Menu.Item key='/delivery-rules' disabled={user.role != 'superadmin'}>
            <Link to='/delivery-rules' />
            <Icon type='close-square' />
            <span>Regras Envio</span>
          </Menu.Item>
          <Menu.Item key='/deliverables'>
            <Link to='/deliverables' />
            <Icon type='file-text' />
            <span>Entregáveis</span>
          </Menu.Item>
          <Menu.Item key='/hype' disabled={user.role != 'superadmin'}>
            <Link to='/hype' />
            <Icon type='api' />
            <span>Transações Hype</span>
          </Menu.Item>
          <Menu.Item key='/deliveries'>
            <Link to='/deliveries' />
            <Icon type='mail' />
            <span>Entregas</span>
          </Menu.Item>
          <Menu.Item key='/templates' disabled={user.role == 'monitor'}>
            <Link to='/templates' />
            <Icon type='copy' />
            <span>Templates</span>
          </Menu.Item>
          <Menu.Item key='/events' disabled={user.role != 'superadmin'}>
            <Link to='/events' />
            <Icon type='carry-out' />
            <span>Eventos</span>
          </Menu.Item>
          <Menu.Item key='/customers' disabled={true}>
            <Link to='/customers' />
            <Icon type='team' />
            <span>Clientes</span>
          </Menu.Item>
          <Menu.Item key='/users' disabled={user.role != 'superadmin'}>
            <Link to='/users' />
            <Icon type='customer-service' />
            <span>Usuários</span>
          </Menu.Item>
          <Menu.Item key='/logs'>
            <Link to='/logs' />
            <Icon type='file-search' />
            <span>Logs</span>
          </Menu.Item>
        </Menu>
      </Sider>
    )
  }
}
